<template>
  <Modal
      :footer-hide="true"
      v-model="modal"
      :mask-closable="false"
      :lock-scroll="true"
      class-name="vertical-center-modal"
  >
    <div class="login_right">
      <p class="login">{{ $t('uc.login.login') }}</p>
      <Form ref="formInline" :model="formInline" :rules="ruleInline" inline style="margin-top: 10%;">
        <FormItem prop="user" class="captcha">
          <Input type="text" size="large" v-model="formInline.username" :placeholder="$t('uc.login.usertip')"
                 clearable border>
            <Select v-model="country" slot="prepend" style="width: 80px;">
              <img src="../assets/logo/pad.png" slot="prefix" width="20" alt="">
              <Option value="+855" label="+855">
                <div style="display: flex;justify-content: space-evenly;align-items: center;">
                  <img src="../assets/nations/cambodia.png" width="20" alt="">
                  <span style="margin-left: 5px;">+855</span>
                </div>
               
                <!-- <span style="margin-left:10px;color:#ccc">{{ $t('uc.regist.cambodia') }}</span> -->
              </Option>
              <Option value="+86" label="+86">
                <div style="display: flex;justify-content: space-evenly;align-items: center;">
                  <img src="../assets/nations/china.png" width="20" alt="">
                <span style="margin-left: 5px;">+86</span>
                </div>
               
                <!-- <span style="margin-left:10px;color:#ccc">{{ $t('uc.regist.china') }}</span> -->
                </Option>
              <Option value="+65" label="+65">
               
                <div style="display: flex;justify-content: space-evenly;align-items: center;">
                  <img src="../assets/nations/Singapore.png" width="20" alt="">
                <span style="margin-left: 5px;">+65</span>
                </div>
               
                <!-- <span style="margin-left:10px;color:#ccc">{{ $t('uc.regist.singapore') }}</span> -->
              </Option>
              <Option value="+82" label="+82">
             
                <div style="display: flex;justify-content: space-evenly;align-items: center;">
                  <img src="../assets/nations/korea.png" width="20" alt="">
                  <span style="margin-left: 5px;">+82</span>
                </div>
                <!-- <span style="margin-left:10px;color:#ccc">{{ $t('uc.regist.korea') }}</span> -->
                </Option>
              <Option value="+81" label="+81">
               
                <div style="display: flex;justify-content: space-evenly;align-items: center;">
                  <img src="../assets/nations/japan.png" width="20" alt="">
                <span style="margin-left: 5px;">+81</span>
                </div>
                <!-- <span
                  style="margin-left:10px;color:#ccc">{{ $t('uc.regist.japan') }}</span> -->
                </Option>
              <Option value="+66" label="+66">
               
                <div style="display: flex;justify-content: space-evenly;align-items: center;">
                  <img src="../assets/nations/thai.png" width="20" alt="">
                <span style="margin-left: 5px;">+66</span>
                </div>
                <!-- <span
                  style="margin-left:10px;color:#ccc">{{ $t('uc.regist.thailand') }}</span> -->
                </Option>
              <Option value="+84" label="+84">
               
                <div style="display: flex;justify-content: space-evenly;align-items: center;">
                  <img src="../assets/nations/vietnam.png" width="20" alt="">
                <span style="margin-left: 5px;">+84</span>
                </div>
                <!-- <span
                  style="margin-left:10px;color:#ccc">{{ $t('uc.regist.vietnam') }}</span> -->
                </Option>
              <Option value="+62" label="+62">
               
                <div style="display: flex;justify-content: space-evenly;align-items: center;">
                  <img src="../assets/nations/indiaN.png" width="20" alt="">
                <span style="margin-left: 5px;">+62</span>
                </div>
                <!-- <span
                  style="margin-left:10px;color:#ccc">{{ $t('uc.regist.india') }}</span> -->
                </Option>
              <Option value="+852" label="+852">
               
                <div style="display: flex;justify-content: space-evenly;align-items: center;">
                  <img src="../assets/nations/hk.png" width="20" alt="">
                  <span style="margin-left: 5px;">+852</span>
                </div>
                <!-- <span
                  style="margin-left:10px;color:#ccc">{{ $t('uc.regist.hk') }}</span> -->
                </Option>
              <Option value="+60" label="+60">
               

                <div style="display: flex;justify-content: space-evenly;align-items: center;">
                  <img src="../assets/nations/mala.png" width="20" alt="">
                <span style="margin-left: 5px;">+60</span>
                </div>
                <!-- <span
                  style="margin-left:10px;color:#ccc">{{ $t('uc.regist.malaysia') }}</span> -->
                </Option>
              <Option value="+886" label="+886">
               
                <div style="display: flex;justify-content: space-evenly;align-items: center;">
                  <img src="../assets/nations/taiwan.png" width="20" alt="">
                  <span style="margin-left: 5px;">+886</span>
                </div>
                <!-- <span
                  style="margin-left:10px;color:#ccc">{{ $t('uc.regist.taiwan') }}</span> -->
                </Option>
              <!-- <Option value="+673" label="+673"><span>+673</span>
                <span
                  style="margin-left:10px;color:#ccc">{{ $t('uc.regist.wenlai') }}</span>
                </Option> -->
            </Select>
          </Input>
        </FormItem>
        <FormItem prop="password" class="captcha">
          <Input type="password" v-model="formInline.password" size="large"
                 :placeholder="$t('uc.login.pwdtip')" password>
          </Input>
        </FormItem>
        <p id="notice" class="hide">{{ $t('uc.login.validatemsg') }}</p>
        <FormItem class="captcha">
          <Button class="login_btn" @click="handler">{{ $t('uc.login.login') }}</Button>
        </FormItem>
        <!--忘记密码-->
        <div class='to_register' @click="forgetPwd">
          <img src="../assets/logo/1212.png" width="20" alt="">
          <div style="color: #fff;">{{ $t('uc.login.forget') }}</div>
        </div>
        <div class="captcha bot" @click="changeModal">
          <span style="color: #b1bad3;">{{ $t('uc.finance.xx163') }}</span>
          <div to="/MobileRegister" style="color: #fff;">{{ $t('uc.login.goregister') }}</div>
        </div>
      </Form>
    </div>
  </Modal>
</template>
<script>

import {getQueryVariable} from '@/config/index'

export default {
  data() {
    return {
      modal: false,
      from: '',
      country: "+855",
      captchaObj: null,
      fullscreen: false,
      formInline: {
        username: "",
        password: "",
      },
      iwidth: '',
      iHeight: '',
      ruleInline: {
        username: [
          {
            required: true,
            message: this.$t("uc.login.loginvalidate"),
            trigger: "blur"
          }
        ],
        password: [
          {
            required: true,
            message: this.$t("uc.login.pwdvalidate1"),
            trigger: "blur"
          },
          {
            type: "string",
            min: 6,
            message: this.$t("uc.login.pwdvalidate2"),
            trigger: "blur"
          }
        ]
      },
      options: [
        {
          title: "en-US",
          cardImage: "../assets/img/icons8-usa-96.png"
        },
        {
          title: "zh-HK",
          cardImage: "../assets/img/icons8-hongkong-96.png"
        },
        {
          title: "zh-CN",
          cardImage: "../assets/img/icons8-china-96.png"
        },

      ],
      country_en: ''
    };
  },
  created: function () {
    this.init();
    this.iwidth = document.documentElement.clientWidth;
    this.iHeight = document.documentElement.clientHeight;
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name === 'register') {
        vm.from = 'register'
      }
    })
  },
  computed: {
    languageValue: function () {
      var curlang = this.$store.getters.lang;
      if (curlang == "en-US") {
        return "English";
      }
      if (curlang == "ja-JP") {
        return "日本語";
      }
      if (curlang == "ko-KR") {
        return "한국어";
      }
      if (curlang == "de_DE") {
        return "Deutsche";
      }
      if (curlang == "fr_FR") {
        return "français";
      }
      if (curlang == "it_IT") {
        return "italiano";
      }
      if (curlang == "es_ES") {
        return "Español";
      }
      if (curlang == "zh-HK") {
        return "繁體中文";
      }
      if (curlang == "zh-CN") {
        return "简体中文";
      }
      return curlang;
    },
    lang() {
      return this.$store.state.lang;
    },
    showModal() {
      return this.$store.state.showLogin
    }
  },
  watch: {
    showModal(newVal, old) {
      this.modal = newVal
    },
    modal(newVal, old) {
      this.$store.commit("showLoginFn", newVal)
    }
  },
  methods: {
    forgetPwd(){
      this.$store.commit('showLoginFn', false)
      this.$router.push({path:"/findPwd"})
    },
    changeModal() {
      this.$store.commit('showRegisterFn',true)
    },
    toRegister() {
      this.$router.push("/MobileRegister");
    },
    somebodyLogin() {
      let member = {}
      member.isNobody = true
      this.$store.commit("setMember", member);
      this.$router.push("/about");
    },
    changelanguage: function (name) {
      console.log("change language: " + name);
      this.$store.commit("setlang", name);
      this.$i18n.locale = name;
      this.country_en = name
    },

    init() {
      if (getQueryVariable("agentId")) {
        this.$store.commit("setCurrency", getQueryVariable("agentId"));
      } else if (getQueryVariable("tgId")) {
        let tgid = getQueryVariable("tgId")
        let amount = getQueryVariable("amount")
        let gameid = getQueryVariable("gameId")
        this.$router.push({name: "TgTransition", params: {tgid, amount, gameid}}); //添加红包判断
      }
      let lang = localStorage.getItem('LANGUAGE');
      this.country_en = lang || "zh-CN"
      this.$i18n.locale = this.country_en;
    },
    handler() {
      this.handleSubmit("formInline"); // 屏蔽了验证
    },
    getMemberInfo() {
      let self = this;
      this.$http
          .post(this.host + this.api.uc.memberInfo)
          .then(response => {
            var resp = response.body;
            if (resp.code == "0000") {
              localStorage.setItem("MEMBER", JSON.stringify(null));
              self.$store.commit("setMember", resp.data);
              // self.$router.replace({path: '/'})
              window.location.reload()
            } else {
              self.$Message.error(resp.mesg);
            }
          });
    },
    handleSubmit(name) {
      // 不带验证
      this.$refs[name].validate(valid => {
        if (valid) {
          var params = {};
          let xy = this.country.substring(1, this.country.length)
          params['code'] = xy
          // params['deviceInfo'] = "3e3c41b2-62fd-48b3-b51e-fd202c19b456"
          params["mobile"] = this.formInline.username;
          params["password"] = this.formInline.password;
          params["fromPc"] = 1;
          this.$http.post(this.host + this.api.uc.login, params).then(response => {
            var resp = response.body;
            if (resp.code == "0000") {
              // this.$store.commit("setMember", resp.data);
              this.$store.commit('setCode', xy);
              localStorage.setItem('TOKEN', resp.data.token);
              if (this.$route.query.key != null && this.$route.query.key != "") {
                localStorage.setItem("USERKEY", this.$route.query.key);
              }
              this.$store.commit('showLoginFn', false)
              this.getMemberInfo()
              this.$http.post(this.host + this.api.uc.joinList).then(res => {  //请求活动接口
                let result = res.body
                if (result.code == '0000') {
                  if (result.data != null && result.data.length > 0) {
                    let activityId = result.data[0].activityId
                    let activityType = result.data[0].activityType
                    this.$router.push({name: "About", params: {activityId, activityType}}); //添加红包判断
                  } else
                    if(this.$route.name !== 'Sports'){
                      this.$router.push({name: "About"}); //添加红包判断
                    }
                } else {
                  this.$Notice.error({
                    title: this.$t("common.tip"),
                    desc: resp.mesg
                  });
                }
              })
            } else {
              // this.$Message.error(resp.mesg);
              this.$Notice.error({
                title: this.$t("common.tip"),
                desc: resp.mesg
              });
            }
          });
        }
      });
    }
  }
};
</script>
<style scoped lang="scss">

.login_right {
  background-color: #1A2C38;
  padding: 10px;
  width: 100%;
  border-radius: 10px;
  height: 350px;

  .login {
    color: #fff;
    font-weight: 600;
    font-size: 18px;
  }

  form.ivu-form.ivu-form-label-right.ivu-form-inline {
    .login_title {
      height: 70px;
      color: #fff;
      font-size: large;
    }
  }
}

.login_btn.ivu-btn {
  width: 100%;
  height: 42px;
  border-radius: 4px;
  outline: none;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 15px;
  border: none;
  color: #000;
  font-weight: 600;
  background-color: #00E700;

  &:focus {
    -moz-box-shadow: 0px 0px 0px #fff, 0px 0px 0px #fff;
    -webkit-box-shadow: 0px 0px 0px #fff, 0px 0px 0px #fff;
    box-shadow: 0px 0px 0px #fff, 0px 0px 0px #fff;
  }
}

.to_register {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  color: #fff;
  font-weight: bold;

}

.captcha {
  width: 90%;
  margin-left: 5%;
}

.bot {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.hide {
  display: none;
}

#notice {
  color: red;
}

.user .ivu-btn,
.ivu-btn:active,
.ivu-btn:focus {
  border-color: #d7dde4;
  box-shadow: none;
}

/deep/ .ivu-modal-body {
  padding: 0;
  border-radius: 100px;
}

/deep/ .ivu-modal-content {
  width: 96%;
  margin: auto;
  border-radius: 20px;
}

/*  */
</style>
<style lang="scss" scoped>


/deep/ .ivu-input-group-prepend {
  border-radius: 8px;
  border: none;
  background-color: #0E212E;
  color: #fff;
}

/deep/ .ivu-select {
  color: #fff;
}

/deep/ .ivu-input {
  border-radius: 8px;
  border: none;
  background-color: #0E212E;
  color: #fff;
}

/deep/ .ivu-input::-moz-placeholder {
  color: $color-highlight-background;
  font-size: medium;
}

/deep/ .ivu-input::-webkit-input-placeholder {
  color: $color-highlight-background;
  font-size: medium;
}

/deep/ .ivu-input::-ms-input-placeholder {
  color: $color-highlight-background;
  font-size: medium;
}


/deep/ .ivu-form-item-content {
  border: 1px solid #2f4553;
  border-radius: 8px;
}
</style>
<style>
.ivu-select-arrow {
  right: 4px;
}

.ivu-select-item span:first-child {
  display: inline-block;
  width: 30px;
  text-align: left;
}

/deep/ .ivu-form-item-content {
//border: 1px solid #2f4553;
}

.vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .ivu-modal {
    top: 0;
  }
}
</style>
