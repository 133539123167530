import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from './views/Home.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err)
}


const routes = [
    {
        path: '/login',
        name: 'Home',

        component: Home,
        meta: {
            index: 0,
        },
    },
    {
        path: '/',
        name: 'About',
        meta: {
            index: 0,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ './views/About.vue'),
        children: [
            {
                path: 'gameAll',
                name: 'GameAll',
                component: () => import("./views/gameAll.vue")
            },
            {
                path: 'gameAllTing',
                name: 'GameAllTing',
                component: () => import("./views/gameAllTing.vue")
            },
            {
                path: 'provider',
                name: 'provider',
                component: () => import("./views/provider.vue")
            },
        ]
    },
    {
        path: '/sports',
        name: 'Sports',
        meta: {
            index: 0,
        },
        component: () => import(/* webpackChunkName: "about" */ './views/sports.vue')
    },
    {
        path: '/active',
        name: 'Active',
        meta: {
            index: 0,
        },
        component: () => import(/* webpackChunkName: "about" */ './views/active.vue')
    },
    {
        path: '/MobileRegister',
        name: 'register',
        meta: {
            index: 1,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ './views/MobileRegister.vue')
    },
    {
        path: '/Recharge',
        name: 'recharge',
        meta: {
            index: 0,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ './views/Recharge.vue')
    },
    {
        path: '/Withdraw',
        name: 'withdraw',
        meta: {
            index: 0,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ './views/Withdraw.vue')
    },
    {
        path: '/FindPwd',
        name: 'findpwd',
        meta: {
            index: 1,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ './views/FindPwd.vue')
    },
    {
        path: '/me',
        name: 'me',
        meta: {
            index: 0,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ './views/me.vue')
    },
    {
        path: '/my',
        name: 'my',
        meta: {
            index: 0,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ './views/my.vue')
    },
    {
        path: '/finde',
        name: 'finde',
        meta: {
            index: 0,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ './views/finde.vue')
    },
    {
        path: '/set',
        name: 'set',
        meta: {
            index: 0,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ './views/set.vue')
    },
    {
        path: '/loginset',
        name: 'loginset',
        meta: {
            index: 0,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ './views/loginset.vue')
    },
    {
        path: '/profile',
        name: 'profile',
        meta: {
            index: 0,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ './views/profile.vue')
    },
    {
        path: '/cart',
        name: 'Cart',
        meta: {
            index: 0,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ './views/cart.vue')
    },
    {
        path: '/category',
        name: 'category',
        meta: {
            index: 0,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ './views/category.vue')
    },
    {
        path: '/GamePage',
        name: 'game',
        meta: {
            index: 0,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ './views/GamePage.vue')
    },
    {
        path: '/GamePage1',
        name: 'game',
        meta: {
            index: 0,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ './views/GamePage.vue')
    },
    {
        path: '/GamePagePP',
        name: 'game',
        meta: {
            index: 0,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ './views/GamePagePP.vue')
    },
    {
        path: '/gamedetail',
        name: 'gamedetail',
        meta: {
            index: 0,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ './views/gamedetail.vue')
    },
    {
        path: '/trans',
        name: 'Transaction',
        meta: {
            index: 0,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ './views/trans.vue')
    },
    {
        path: '/agentdata',
        name: 'AgentData',
        meta: {
            index: 0,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ './views/agentdata.vue')
    },
    {
        path: '/promotion',
        name: 'Promotion',
        meta: {
            index: 0,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ './views/promotion.vue')
    },
    {
        path: '/customservice',
        name: 'CustomService',
        meta: {
            index: 0,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ './views/customservice.vue')
    },
    {
        path: '/dwrecords',
        name: 'DWrec',
        meta: {
            index: 0,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ './views/DWrecords.vue')
    },
    {
        path: '/subdetail',
        name: 'SubDetail',
        meta: {
            index: 0,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ './views/subGamedetail.vue')
    },
    {
        path: '/tgTransition',
        name: 'TgTransition',
        meta: {
            index: 0,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ './views/tgTransition.vue')
    },
    {
        path: '/advertise',
        name: 'Advertise',
        meta: {
            index: 0,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ './views/advertise.vue')
    },
    {
        path: '/adetail',
        name: 'Adetail',
        meta: {
            index: 0,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ './views/adetail.vue')
    },
    {
        path: '/wallet',
        name: 'Wallet',
        meta: {
            index: 0,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ './views/wallet.vue')
    },
    {
        path: '/profile',
        name: 'Profile',
        meta: {
            index: 0,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ './views/profile.vue')
    },
    {
        path: '/exchange',
        name: 'Exchange',
        meta: {
            index: 0,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ './views/exchange.vue')
    },
    {
        path: '/agentPage',
        name: 'AgentPage',
        meta: {
            index: 0,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ './views/agentPage.vue')
    },
    {
        path: '/dataPage',
        name: 'DataPage',
        meta: {
            index: 0,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ './views/dataPage.vue')
    },
    {
        path: '/members',
        name: 'Members',
        meta: {
            index: 0,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ './views/members.vue')
    },
    {
        path: '/commision',
        name: 'Commision',
        meta: {
            index: 0,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ './views/commision.vue')
    },
    {
        path: '/commisionDetailPage',
        name: 'CommisionDetail',
        meta: {
            index: 0,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ './views/commisionDetailPage.vue')
    },
    {
        path: '/tabCS',
        name: 'TabCS',
        meta: {
            index: 0,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ './views/tabCS.vue')
    },
    {
        path: '/aboutUS',
        name: 'AboutUS',
        meta: {
            index: 0,
        },

        component: () => import(/* webpackChunkName: "about" */ './views/aboutUS.vue')
    },
    {
        path: '/cardMaker',
        name: 'CardMaker',
        meta: {
            index: 0,
        },

        component: () => import(/* webpackChunkName: "about" */ './views/cardMaker.vue')
    },
    {
        path: '/usdtList',
        name: 'UsdtList',
        meta: {
            index: 0,
        },

        component: () => import(/* webpackChunkName: "about" */ './views/usdtList.vue')
    },
    {
        path: '/walletSetup',
        name: 'walletSetup',
        meta: {
            index: 0,
        },

        component: () => import(/* webpackChunkName: "about" */ './views/walletSetup.vue')
    },
    {
        path: '/usdtMaker',
        name: 'UsdtMaker',
        meta: {
            index: 0,
        },

        component: () => import(/* webpackChunkName: "about" */ './views/usdtMaker.vue')
    },
    {
        path: '/singlePage',
        name: 'xxx',
        meta: {
            index: 0,
            keepAlive: true
        },

        component: () => import(/* webpackChunkName: "about" */ './views/singlePage.vue')
    },
    {
        path: '/browse',
        name: 'browse',
        meta: {
            index: 0,
            // keepAlive: true
        },

        component: () => import(/* webpackChunkName: "about" */ './views/browse.vue'),
        children: [
            {
                path: 'search',
                name: 'search',
                component: () => import("./views/search.vue")
            },
        ]
    },
    {
        path: '/gameHistory',
        name: 'gameHistory',
        component: () => import("./views/gameHistory.vue")
    },
    {
        path: '/vip',
        name: 'vip',
        component: () => import("./views/vip.vue")
    },
    {
        path: '/lianmeng',
        name: 'lianmeng',

        component: () => import('./views/lianmeng.vue'),
    },
    {
        path: '/read',
        name: 'read',

        component: () => import('./views/read.vue'),
    },
    {
        path: '/test',
        name: 'test',

        component: () => import('./views/test.vue'),
    },

    // { path: '/findPwd', component: resolve=>(require(["../pages/uc/findpwd"],resolve)) },
]

const router = new VueRouter({

    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        // always scroll to top
        // console.log(to)
        // console.log(from)
        // console.log(savedPosition)
        // return { top: 0 }
        if (savedPosition) {
            return savedPosition
        } else {
            return {x: 0, y: 0}
        }
    },
})

// router.beforeEach(async (to, from, next) => {

//   if (store.state.member == null) {
//     console.log(from);
//     next()
//   }

//   else if (
//     // make sure the user is authenticated
//     store.state.member.isNobody && router.app.$store.state.member.isNobody == true &&
//     // ❗️ Avoid an infinite redirect
//     to.name != 'About'
//   ) {
//     // redirect the user to the login page
//     return { name: 'About' }
//   }
// })

export default router
