module.exports = {  //越南
    common: {
        lang: 'Tiếng Việt',
        login1: 'Bạn co săn san để tạo một tai khoản? Hãy đăng nhập！',
        register: 'Đăng ký',
        logout: 'Đăng Xuất',
        tip: 'Thông Báo',
        logintip: 'lòng đăng nhập',
        set: 'Cài đặt',
        amount: "Số Dư",
        close: "Xuất",
        ok: "Chắc chắn",
        capitalPass: 'Hồ Sơ',
        capitalPassinput: 'Cài Đặt Mật Khẩu Rút Tiền',
        realNameInput: 'Ngân hàng tên',
        huioneNameInput: 'HUIONE Tên',
        huioneName: 'HUIONE Tài Khoản',
        nicknameInput: 'Tên Hiển Thị',
        usertip: 'hoàn tất chuyển khoản',
        tibifirst: "Chi tiết",
        message: "Thông Báo",
        service: 'Thể thao  Chat',
        ads: 'Khuyến Mãi',
        aboutus: 'Về Chúng Tôi',
        pleaseselectService: "Chọn Phương Thức",
        pleaseselectCurrency: "Tiền Tệ Của Tôi",
        currencyType: 'Chọn Tiền Tệ',
        pleaseselectService1: "Phương thức rút tiền",
        pleaseselectService2: "Chuyển Đường",
        tabbar1: 'Trang Chủ',
        tabbar2: 'Khuyến Mãi',
        tabbar3: 'CSKH',
        tabbar4: 'Tài Khoản',
        date: 'Vui lòng chọn khoảng thời gian',
        date1: 'Thành Viên Tuyến Dưới',
        date2: 'Tổng Thắng/Thua',
        date3: 'Tổng số tiền đặt cược',
        date4: 'Tài Khoản',
        date5: 'Tiền Cược',
        date6: 'Thua/ Thắng ',
        date7: 'Tổng số Cược Hợp Lệ',
        date8: 'Cược Hợp Lệ',
        date9: ' Đại Lý',
        date10: 'Tài Khoản Thành Viên :',
        exchange: 'Quy Đổi',

        notice23: 'Nhắc nhở ấm áp: Hiện tại người chơi chỉ có thể chuyển tiền vào tài khoản đại lý, vui lòng biết !',
        categorySport: 'Thể Thao',
        categoryLive: 'Casino',
        categorySlot: 'Slots ',
        categoryFish: 'Bắn Cá',
        categoryBingo: 'Xổ Số',
        categoryPoke: 'Game Bài',
        gender: 'Giới Tính',
        birthday: 'Sinh Nhật',
        nickname: 'Tên Hiển Thị',
        male: 'Nam',
        female: 'Nữ',
        name: 'Họ Tên',
        usdt: 'USDT',
        usd: 'đô la',
        exchangeAll: 'Tất cả',
        gOut: 'Chuyển tài khoản',
        goIn: 'Tài khoản tiền gửi',
        meamount: 'Số Dư',
        mebonus: 'Thưởng',
        agSport: 'AG 体育',
        sbSport: '沙巴 体育',
        ibSport: 'BTI 体育',
        dgLive: 'DG 真人',
        agLive: 'AG 真人',
        gbLive: 'BBIN 真人',
        E0Live: 'EVO 真人',
        wcLive: 'WM 真人',
        oneKey: '一键归账'

    },
    uc: {
        login: {
            login: 'Đăng Nhập',
            usertip: 'Số điện thoại',
            pwdtip: 'Mật khẩu',
            validatecodeload: 'Mã xác nhận',
            validatemsg: 'Vui lòng hoàn tất xác minh trước',
            forget: 'Quên Mật Khẩu',
            loginvalidate: 'Tài Khoản',
            pwdvalidate1: 'Mật khẩu',
            pwdvalidate2: 'Độ dài mật khẩu không thể ít hơn 6 ký tự',
            success: 'Đăng nhập thành công',
            goregister: "Đăng ký",
            getlostpwd: "Quên Mật Khẩu",
            nouser: "Người dùng không tồn tại",
            normaluser: 'Tài khoản của bên kia không hỗ trợ chuyển khoản',
            slogan: '亚洲最信赖博彩平台之一',
            toSetting: 'Sửa Đổi',
            settinghuione: 'HUIONE Tên',
            settingbank: 'Tên người dùng thẻ ngân hàng'
        },
        regist: {
            smscode: 'Mã xác nhận',
            sendcode: 'gửi',
            resendcode: 'Gửi lại(',
            pwd: 'Mật khẩu đăng nhập',
            repwd: 'Mật khẩuĐăng Nhập',
            agreement: 'tôi đã đọc và đồng',
            userprotocol: 'Thoả Thuận Mở Tài Khoản',
            regist: 'Đăng ký',
            teltip: 'Số điện thoại',
            telerr: 'Vui lòng nhập đúng số điện thoại',
            emailtip: 'Thư',
            emailerr: 'Tên tài khoản hoặc mật khẩu không đúng, vui lòng nhập lại',
            confirmpwdtip: 'Mật Lại mật khẩu',
            confirmpwderr: 'Mật khẩu sai!',
            telregist: 'Đăng ký điện thoại của bạn',
            emailregist: 'Đăng ky email',
            smscodetip: 'Mã xác nhận',
            pwdtip: 'Mật khẩu đăng nhập',
            pwdmsg: 'Độ dài mật khẩu không thể ít hơn 6 ký tự',
            telno: 'Số điện thoại',
            email: 'Thư',
            agreementtip: 'Vui lòng xác nhận',
            modaltitle: 'Xin vui lòng xác minh',
            promotion: 'Đại LýID(非必填)',
            china: '简体中文',
            singapore: 'English',
            thailand: 'แบบไทย',
            vietnam: 'Tiếng Việt',
            india: 'Indonesia',
            japan: 'にほんご ',
            korea: '한국어',
            hk: '繁體中文',
            malaysia: 'Melayu',
            taiwan: '繁體中文',
            cambodia: 'កម្ពុជា។',
            uk: 'English'
        },
        forget: {
            newpwd: 'Mật khẩu mới',
            confirmpwd: 'Mật Lại mật khẩu',
            confirmpwd11: 'Tài Khoản Thành Viên',
            save: 'Nộp',
            pwdvalidate1: 'Mật khẩu',
            pwdvalidate2: 'Mật khẩu sai!',
            resettelpwd: 'Sửa Đổi Mật Khẩu Đăng Nhập',
            resetemailpwd: 'Sửa Đổi Mật Khẩu Rút Tiền',
            newpwdtip: 'Mật khẩu mới',
            pwdvalidate3: 'Độ dài mật khẩu không thể ít hơn 6 ký tự',
            smscode: 'Mã xác nhận',
            teltip: 'Số điện thoại',
            smscodetip: 'Mã xác nhận',
            email: 'Thư',
            emailcode: 'Mã xác nhận',
            emailtip: 'Thư',
            emailcodetip: 'Mật Khẩu Rút Tiền',
            resetpwdsuccess: 'Hoàn thành cập nhật',
            addresstip: 'Thêm Địa Chỉ Rút Tiền',
            addresstip21: 'Tài Khoản Thành Viên',
            addresstip221: 'Tài Khoản',
            addresstip222: 'Tài Khoản Ngân Hàng',
            emailcodetip1: 'Cài Đặt Mật Khẩu Rút Tiền',
            emailcodetip11: 'Thêm Địa Chỉ Rút Tiền',
            walletaddress: 'Quản Lý Địa Chỉ',
            huioneaddress: 'HUIONE Tài Khoản',
            walletaddress1: 'Quản Lý Địa Chỉ',
            walletaddress2: 'Số tiền thực tế nhận được',
            walletaddress21: 'Số tiền phải > 0',
            wallet: 'Ví Tiền',
            userID: 'Tài Khoản Thành Viên',
            upload: 'Bấm để tải lên biên lai',
            addresstip31: 'HUIONE Họ Tên',
            addresstip41: 'HUIONE Tài Khoản',
            addresstip311: 'Họ Tên',
            addresstip321: 'Họ Tên',
            personal: 'Hồ Sơ'
        },
        finance: {
            center: 'Hồ Sơ',
            personalassets: 'Mạng lưới gửi tiền/rút tiền',
            swapassets: 'Quản Lý Địa Chỉ',
            swapassets1: 'Tài Khoản',
            recordetail: 'Lịch Sử Giao Dịch',
            tradetail: 'Số Dư',
            invitingmining: 'Số Tiền',
            charge: 'Nạp Tiền',
            pickup: 'Rút Tiền',
            copy: 'sao chép',
            choose: 'Lựa chọn',
            cards: 'Thẻ',
            copysuccess: 'Đã sao chép thành công',
            copyfailed: 'Sao chép không thành công',
            notice: "Phí xử lý/手续费由会员支付",
            notice1: "每位会员都有独立的USTD存款地址, 转账成功后系统会根据转账金额自动到账,  如遇长时间未到账请及时联系主页客服进行咨询",
            notice11: 'Nếu lâu ngày không nhận được tài khoản, vui lòng liên hệ bộ phận chăm sóc khách hàng để được tư vấn kịp thời.',
            transfee: "Phí xử lý",
            emailcodetip: 'Mật Khẩu Rút Tiền',
            gamedetail: 'Trò Chơi',
            gametype: 'Loại trò chơi',
            game0: 'WL.',
            game81: 'Casino',
            game82: 'Game Bài',
            game83: '电子游戏',
            game84: 'Trò chơi thể thao',
            game85: 'Slot Game',
            game86: 'XỔ SỐ',
            game87: '捕鱼游戏',
            game88: '扑克游戏',
            game89: '迷你游戏',
            game90: '其他游戏',
            game91: '视讯色碟',
            game92: '视讯保险百家乐',
            game100: 'WL Thể Thao',
            bet: 'Chi Tiết Đặt Cược',
            validBet: 'Cược Hợp Lệ',
            profit: 'Thắng/Thua',
            addUaddress: 'Thẻ ngân hàng',
            addaddress: 'Thêm Địa Chỉ Rút Tiền',
            trans: 'Chuyển',
            agentdata: 'Báo Cáo Hoa Hồng',
            agentpage: 'Hợp Tác',
            timezone: 'Cài Đặt Múi Giờ',
            agentapply: 'Đụng ngay bây giờ',
            agentnotice: 'Đăng ký làm đại lý',
            datapage: 'Báo Cáo Hoa Hồng',
            promotion: 'Hiệu Ứng Quảng Cáo',
            promotion1: 'Quảng cáo mã QR',
            promotion2: 'Lưu hình ảnh',
            promotion3: 'Link Quảng Cáo',
            notice12: "Sau khi địa chỉ rút tiền được đặt thành công, nó chỉ có thể được sửa đổi bằng cách liên hệ với bộ phận dịch vụ khách hàng.",
            notice123: "Tài Khoản Chuyển Của Thành Viên",
            notice1234: "Thao tác thành công và sẽ được xử lý kịp thời, vui lòng đợi.！",
            notice12345: "Cược Hợp Lệ",
            xx1: 'Số lệnh đặt cọc',
            xx2: 'Rút số lệnh',
            xx3: 'Trạng Thái',
            xx4: 'Đang Xét Được',
            xx5: 'Thành công',
            xx6: 'Thất bại',
            xx7: 'Họ Tên',
            xx8: 'Tài Khoản',
            xx9: 'Họ Tên',
            xx10: 'Tài Khoản',
            xx11: 'Trạng Thái',
            xx12: 'Thời gian',
            xx13: 'Tài Khoản Thành Viên',
            xx14: 'Chưa có',
            xx15: 'Người dùng Chuyển',
            xx17: 'Chuyển và rút tiền mặt',
            xx18: 'Chuyển và nạp tiền',
            xx19: 'Nạp Tiền Trực Tuyến',
            xx20: 'Nạp Tiền Trực Tuyến',
            xx21: 'Rút tiền U-Wallet',
            xx22: 'Rút Tiền Trực Tuyến',
            xx23: 'Nạp Tiền',
            xx24: 'Rút Tiền',
            xx25: 'Bonus',
            xx26: 'tạo nên điểm',
            xx27: 'Điểm bị trừ',
            xx28: 'Đăng ký phong bì màu đỏ',
            xx29: 'Mã Giao Dịch',
            xx30: 'Thời gian',
            xx31: 'Số dư sau giao dịch',
            xx32: 'Tỷ Lệ Hoa Hồng',
            xx33: 'Kính gửi các thành viên Jinbei',
            xx34: 'Thành Viên Tuyến Dưới',
            xx35: 'Thành Viên Mới Tháng Này',
            xx36: 'Thành Viên Tích Cực Tháng Này',
            xx37: 'Thắng Hoặc Lỗ Ròng Tháng Này',
            xx38: 'Tỷ Lệ Hoa Hồng',
            xx39: 'Hoa hồng tháng này',
            xx40: 'Quảng cáo mã QR',
            xx41: 'Tên Miền Chuyên Dụng',
            xx42: 'Danh Sách Thành Viên Tuyến Dưới',
            xx43: 'Báo Cáo Hoa Hồng',
            xx44: 'Tìm tài khoản ',
            xx45: 'Tài Khoản Thành Viên',
            xx46: 'Lần Đăng Nhập Cuối',
            xx47: 'Đăng ký',
            xx48: 'Hoa Hồng Kỳ Này',
            xx49: 'Tổng Thắng/Thua',
            xx50: 'Tổng Hoàn Trả',
            xx51: 'Bonus',
            xx52: 'Phí Giao Dịch',
            xx53: 'Phí Thủ Tục Giao Dịch',
            xx54: 'VIP Thưởng Thêm',
            xx55: 'Tiền Thưởng VIP',
            xx56: 'Tỷ Lệ Hoa Hồng',
            xx57: 'Bảng Tóm Tắt Tài Chính',
            xx58: 'Hoa Hồng Kỳ Này',
            xx59: 'Phí nền tảng',
            xx60: 'Rút tài khoản',
            xx61: 'cam Nạp Tiền',
            xx62: 'cam Rút Tiền',
            xx63: 'Thanh toán đang được',
            xx64: 'Thanh toán thành công',
            xx65: 'Thanh toán không thành công',
            xx66: 'Thời gian hoàn thành đơn hàng',
            xx67: 'Phương thức gửi tiền',
            xx68: 'Tiền Tệ',
            xx69: 'Phương thức rút tiền',
            platform: 'Nền tảng chơi game',
            bitStatus: 'Trạng Thái',
            xx70: 'Cược Hợp Lệ',
            xx71: 'Trò chơi đang diễn ra',
            xx72: 'Đặt cược không hợp lệ',
            xx73: 'Thời gian bắt đầu',
            xx74: 'Thời gian kết thúc',
            xx75: 'Chi tiết cá cược',
            xx76: 'Mã Giao Dịch',
            xx77: 'Tài Khoản Thành Viên',
            xx78: 'Số lượng bàn',
            xx79: 'Kết Quả',
            xx80: 'mã trò chơi',
            xx81: 'Đổi Ngôn Ngữ',
            xx82: 'Chào mừng đến với JBBET CSKH',
            xx83: 'HI, Kính thưa khách hàng',
            xx84: 'Người Campuchia,Địch vụ khách hàng tiếng anh',
            xx85: 'Dịch vụ khách hàng người Việt',
            xx86: 'Dịch vụ khách hàng Trung Quốc',
            xx87: 'Địch vụ khách hàng tiếng anh',
            xx88: 'Ngân hàng tên',
            xx89: 'Tài Khoản Ngân Hàng',
            xx91: 'Tên ngân hàng',
            xx92: '24/7 CSKH',
            xx93: 'Thêm Thông Tin Tài Khoản Ngân Hàng',
            xx94: 'Vui lòng liên kết địa chỉ rút tiền (TRC20) để đảm bảo an toàn cho tiền',
            xx95: 'Bạn có chắc chắn muốn quay lại sảnh không? Nếu loại tiền trong trò chơi không được trả lại chính xác, vui lòng sử dụng chức năng hoàn tiền bằng một cú nhấp chuột trên trang của tôi',
            xx96: 'Tên giải đấu',
            xx97: 'Đội chủ nhà',
            xx98: 'Đội địch',
            xx99: 'Phân số',
            xx100: 'Tất cả thông tin đều được yêu cầu',
            xx101: 'Về JBbet',
            xx102: 'JBbet là một điểm đến giải trí cá cược đang nhiều người yêu thích. Đây là sân chơi được đánh giá cao trong top website cá cược uy tín, chất lượng nhất hiện nay. 。JBbet Không chỉ nổi tiếng ở Đông Nam Á, nhà cái này còn giữ vị trí quan trọng tại thị trường cá độ Châu Á.',
            xx103: 'Quá trình ra đời của JBbet',
            xx104: 'Dù chỉ mới nổi tiếng gần đây, nhưng jbbet đã có nhiều năm hoạt động ở thị trường Châu Á. Nhà cái này ra đời từ năm 2005 và hoạt động chính thức tại đất nước Campuchia. Nhờ có PAGCOR hợp tác cấp giấy phép hoạt động. Nhà cái này đã có nhiều hoạt động cá cược, đổi thưởng được người chơi yêu thích.',
            xx105: 'Sản phẩm, dịch vụ giải trí tại JBbet',
            xx106: 'Ngoài các cuộc thi thể thao và giải trí trực tiếp, chúng tôi còn hợp tác với nhiều công ty giải trí lớn. Vì vậy, chúng tôi luôn đảm bảo rằng chúng tôi cung cấp các sản phẩm và dịch vụ giải trí và cờ bạc chất lượng cao nhất. Nó không chỉ mang đến một thị trường cá cược uy tín và chuyên nghiệp. Ngoài ra còn có các sân chơi đa dạng mang đến những lựa chọn tốt nhất cho mọi người.',
            xx107: 'Cá cược thể thao',
            xx108: 'Thì đầu tiên phải nói đến sản phẩm cá cược hot nhất tại đây. Lĩnh vực cá cược thể thao với các trận đấu bóng đá, bóng rổ, bóng chuyền, quần vợt,… vô cùng hấp dẫn. Người chơi tham gia đặt cược, theo dõi trận đấu đầy căng thẳng tại những mùa giải lớn nhất thế giới. Thỏa mãn đam mê cá cược cùng những tỷ lệ kèo chuẩn xác, ưu đãi hoàn cược hấp dẫn.',
            xx109: 'Slot game nổ hũ',
            xx110: 'Một trong những sản phẩm giải trí hấp dẫn nhất tại nhà cái JBbet là slot game nổ hũ. Chúng tôi cung cấp hàng trăm trò slot game đến từ các nhà sản xuất game lớn trên thế giới. Mang đến sự trải nghiệm đa dạng với màu sắc, nội dung và các giải thưởng khác nhau.',
            xx111: 'Slot game nổ hũ',
            xx112: 'Một trong những sản phẩm giải trí hấp dẫn nhất tại nhà cái JBbet là slot game nổ hũ. Chúng tôi cung cấp hàng trăm trò slot game đến từ các nhà sản xuất game lớn trên thế giới. Mang đến sự trải nghiệm đa dạng với màu sắc, nội dung và các giải thưởng khác nhau. ',
            xx113: 'Game bài',
            xx114: 'Bên cạnh Casino live thì tại JBbet vẫn cung cấp các trò bài bạc với đồ họa 3D bắt mắt. Từ các game bài truyền thống như  Cho đến những game bài mới lạ hơn như Rồng Hổ, Baccarat, Blackjack,… ',
            xx115: 'Bắn cá đổi thưởng',
            xx116: 'Bắn cá là trò chơi rất quen thuộc, vừa có tính giải trí cao vừa giúp kiếm thêm tiền cực tốt. Đến với JBbet những trò bắn cá cược biến hoá đa dạng với nội dung, đồ hoạ đặc sắc hơn.',
            xx117: 'Ưu thế của nhà cái JBbet',
            xx118: 'Người chơi tham gia đánh bạc, casino tại MGA không phải lo lắng về tính pháp lý. JBbet được trang bị thiết bị kỹ thuật tiên tiến, sử dụng đội ngũ an ninh chuyên nghiệp, cung cấp hỗ trợ về mối nguy hiểm tại nhà 24/7 và trả lời mọi câu hỏi của bạn. Ưu điểm này cũng giúp chúng tôi tin tưởng nhất khi giới thiệu JBbet tới bạn bè quốc tế. Vì vậy, vui lòng liên hệ bộ phận chăm sóc khách hàng để được tư vấn và giải đáp khi cần thiết.',
            xx119: 'Mong là các bạn sẽ có cái nhìn tổng quan hơn về chúng tôi. Đừng quên theo dõi các tin tức khác tại trang chủ nhà cái để có thêm thông tin bổ ích khác. Đăng ký tài khoản jbbet để có thể tham gia vào sân chơi cá cược đầy sôi động này nữa nhé.',
            xx120: 'Loại tiền này không được hỗ trợ. Vui lòng truy cập trang ví để trao đổi hoặc chuyển đổi tiền tệ.',
            xx121: 'Điền tên thẻ ngân hàng như hình bên dưới',
            xx122: 'Địa chỉ USDT (TRC20)',
            xx123: 'Quản Lý Địa Chỉ',
            xx124: 'Tất cả hồ sơ',
            xx125: 'Hồ sơ gửi tiền',
            xx126: 'Kỷ lục rút tiền',
            xx127: 'Hủy bỏ',
            xx128: 'Hiện đang nộp đơn',
            xx129: 'Đã xác nhận',
            xx130: 'Đã trở lại',
            xx131: 'Kỷ lục thể thao',
            xx132: 'Kỷ lục trò chơi',
            xx133: 'Kết quả',
            xx134: 'Đó là nó',
            xx135: 'Chưa hết',
            xx136: 'Hủy bỏ',
            xx137: 'Hôm qua',
            xx138: 'Hôm nay',
            xx139: '7 ngày qua',
            xx140: 'Gần 30 ngày',
            xx141: 'Cược đơn',
            xx142: 'Đặt cược nhiều lần',
            xx143: 'Quán quân',
            xx144: 'Ngày bạn chọn',
            xx145: ' English customer service',
            xx146: 'trốn',
            xx147: 'Khoản tiền gửi thử',
            xx148: 'Chi Tiết Đặt Cược',
            xx149: 'Thể thao CMD',
            xx150: 'Thể thao Saba',
            xx151: 'GamingSoft',
            xx152: 'Ngày thi đấu',
            xx153: 'USDT rút tiền',
            xx154: 'USDT chuyển giao',
            xx155: 'บริการลูกค้าไทย',
            xx156: 'Layanan pelanggan indonesia',
            xx157: 'JDB Quay Số',
            xx158: 'PG Quay Số',
            xx159: 'PP Quay Số',
            xx160: 'NT Quay Số',
            xx161: 'JDB đánh bắt cá',
            xx162: 'Xin chào, chào mừng bạn đã đến',
            xx163: 'Bạn chưa có tài khoản?',
            xx164: 'Tiền tệ của tôi',
            xx165: 'Trao đổi',
            xx166: 'Đăng ký phong bì màu đỏ',
            xx167: 'Điều chỉnh tài khoản hệ thống CMD',
            xx168: '游戏买断',
            xx169: '游戏买入',
            xx170: 'Giải độc đắc lũy tiến',
            xx171: 'Thưởng',
            xx172: 'Hủy cược',
            xx173: '回滚投注',
            xx174: 'Giải quyết trò chơi',
            xx175: 'Cá cược trò chơi',
            xx176: 'Hoàn tiền nếu rút tiền không thành công',
            xx177: 'Người dùng Chuyển',
            xx178: 'Điểm bị trừ',
            xx179: 'Trừ điểm thưởng',
            xx180: 'tạo nên điểm',
            xx181: 'Bonus',
            xx182: 'CAM Nạp tiền',
            xx183: 'Huione Nạp tiền',
            xx184: 'Udun Nạp tiền',
            xx185: 'Thủ công Nạp tiền',
            xx186: 'Rút Tiền Trực Tuyến',
            xx187: 'Rút tiền Huione',
            xx188: 'withdraw',
            xx223: '彩金扣分',
            xx226: '体育结算'
        },
    },
    foot: {
        t1: "mago Berkomitmen pada perjudian yang bertanggung jawab, untuk informasi lebih lanjut silakan kunjungi",
        "t2": 'Mago.game được sở hữu và điều hành bởi Jinbei Group, công ty đã đăng ký: Altacore N.V. Số đăng ký: 151002, địa chỉ đăng ký: Dr. H. Fergusonweg 1, Gaito, Curacao. Vui lòng liên hệ với chúng tôi tại support@Mago.game. Được cấp phép bởi Ban kiểm soát trò chơi Curacao với số giấy phép OGL/2023/109/0075 kể từ ngày 1 tháng 7 năm 2024. Đại lý thanh toán là: Asia Payment Services Singapore.',
        t3: "mendukung",
        t4: "bekerja samaMitra",
        t5: "media berita",
    },
    new: {
        "ting": "sảnh",
        "magoRecommendation": "Mago gợi ý",
        "slots": "máy đánh bạc",
        "liveCasino": "sòng bạc trực tiếp",
        "gameShow": "trò chơi nhỏ",
        "tableGames": "trò chơi trên bàn",
        "fishing": "câu cá",
        "bingo": "Chơi lô tô",
        "newGame": "trò chơi mới",
        "liveCasinoTxt": "sòng bạc trực tiếp",
        "searchGamePlaceholder": "Tìm kiếm trò chơi",
        "recharge": "nạp tiền",
        "withdraw": "Rút tiền mặt",
        "favorites": "Yêu thích",
        "recentGames": "Lịch sử trò chơi gần đây",
        "vipClub": "Câu lạc bộ VIP",
        "alliancePlan": "Chương trình liên kết",
        "gamesTitle": "trò chơi",
        "sports": "các môn thể thao",
        "gameProvider": "nhà cung cấp trò chơi",   //
        "languageSelection": "Lựa chọn ngôn ngữ",
        "timezone": "múi giờ",//
        "onlineSupport": "Hỗ trợ trực tuyến",
        "aboutUs": "về chúng tôi",
        "antiMoneyLaundering": "Chính sách chống rửa tiền",
        "license": "giấy phép",
        "responsibleGambling": "Đánh bạc có trách nhiệm",
        "termsOfService": "Điều khoản dịch vụ",
        "logout": "từ bỏ",
        "fbLiveCasino": "Video trực tiếp FB",
        "loadingText": "đang tải",
        "recommendation": "mago đề nghị",
        "myFavorites": "bộ sưu tập của tôi",
        "gameHistory": "Kỷ lục trò chơi",
        "noData": "Chưa có dữ liệu",
        "noFavoritesData": "Chưa có dữ liệu thu thập",
        "noGamesData": "Chưa có dữ liệu trò chơi",
        "gameProviderPlaceholder": "nhà cung cấp trò chơi",
        "exclusiveAlliancePlan": "Chương trình liên kết độc quyền",
        "joinMogo": "Tham gia chương trình liên kết của mogo và kiếm tiền bằng cách giới thiệu thương hiệu của chúng tôi! Trở thành đối tác của chúng tôi và nhận tới 55% lợi nhuận của bạn. Bạn sẽ nhận được phần trăm hoa hồng từ doanh thu của những người chơi được đề xuất. Hãy cùng nhau chia sẻ thành công",
        "becomePartner": "Trở thành đối tác",
        "exclusiveVIPExperience": "Trải nghiệm VIP tuyệt vời",
        "unlockExclusiveBenefits": "Mở khóa các lợi ích độc quyền và nhận tiền thưởng mà bạn có thể rút ngay lập tức mà không cần ràng buộc。",
        "register": "đăng ký",
        "vipRankingSystem": "Hệ thống xếp hạng Mago VIP",
        "bettingAmount": "Số tiền đặt cược",
        "vipClubBenefits": "Lợi ích của Câu lạc bộ VIP Mago",
        "yourVIPProgress": "您的VIP详细资料",
        "currentLevel": "cấp độ hiện tại",
        "currentLevelValue": "V",
        "expectedWeeklyMonthlyBonus": "Bạn có thể mong đợi tiền thưởng hàng tuần và hàng tháng dựa trên số lượng chơi của bạn. Càng chơi nhiều, bạn càng nhận được nhiều tiền thưởng。",
        "exclusiveVIPServiceRepresentative": "Được chỉ định một Đại diện Dịch vụ VIP tận tâm, người sẽ hỗ trợ và đáp ứng nhu cầu cá cược của bạn。",
        "recentGamePerformanceBonus": "Xui xẻo? Mỗi khi bạn lên cấp, Mago sẽ cho bạn thêm tiền dựa trên số tiền bạn thua.",
        "levelUpBonus": "Tiền thưởng kiếm được sau khi đạt đến từng cấp độ tiếp theo. Cấp độ bạn đạt càng cao thì phần thưởng nâng cấp càng lớn.。",
        "bespoke": "Làm việc với Đại diện dịch vụ VIP tận tâm của bạn để điều chỉnh lợi ích cho phù hợp với nhu cầu cá cược của bạn。",
        "bronze": "Bronze",
        "silver": "Silver",
        "gold": "Gold",
        "platinum": "Platinum I-III",
        "inpWithPwd": "Vui lòng nhập mật khẩu quỹ",
        "inpConfirmPwd": "Nhập mật khẩu xác nhận tiền",
        "pwdMismatch": "Hai mật khẩu không nhất quán",
        "nameRequired": "Tên không thể trống",
        "pwdLabel": "Mật khẩu quỹ",
        "confirmPwdLabel": "Xác nhận mật khẩu quỹ",
        "fillPrompt": "Hãy điền theo hướng dẫn",
        "saveContinue": "Lưu và tiếp tục",
        "confirmDetails": "Xác nhận thông tin chi tiết của bạn",
        "fillDetails": "Vui lòng điền thông tin chi tiết và xác nhận danh tính của bạn để mở khóa các dịch vụ bổ sung. Thông tin được cung cấp là riêng tư và an toàn。",
        "realNameLabel": "tên thật",
        "birthdayLabel": "sinh nhật của bạn",
        "promotionTitle": "Hoạt động",
        "eventTitle": "Mỹ mở rộng",
        "bonusTitle": "Ace bóng thưởng gấp đôi",
        "readMore": "Đọc thêm",
        "experienceNow": "Hãy thử ngay bây giờ",
        "viewAll": "Xem tất cả",
        "playing": "đang chơi",
        "cancel": "Hủy bỏ",
        "start": "bắt đầu",
        "login": "Đăng nhập",
        "collect": "sưu tầm",
        "today": "Hôm nay",
        "win": "thắng",
        "draw": "phẳng",
        "lose": "thua",
        "setWallet": "Hãy thiết lập ví của bạn và bắt đầu chơi！",
        "goSetPwd": "Đi tới cài đặt",
        "pointsSystem": "hệ thống điểm",
        "Browse": "Duyệt qua",
        "Casino": "Sòng bạc",
        "Bets": "phiếu cược",
        "Sports": "Thể thao",
        "Chat": "Chat",//
        "firstTelegram": "TELEGRAM miniAPP",
        "miniProgramCasino": "sòng bạc trực tuyến",
        "followTelegram": "Theo dõi kênh Telegram",
        "trialBonusChannel": "Nhận tiền thưởng dùng thử",
        "enterNow": "Nhập ngay",
        "announcement": "thông báo",
        "magoJoinFB": "FB Live Casino",
        "exclusiveAnchor": "Phong cách thiết kế mới",
        "t4": "Mang đến cho bạn trải nghiệm khác biệt",
        "playNow": "Hãy chơi ngay bây giờ",
        "aboutUs1": 'Mago娱乐城属于金贝集团所属经营，Mago是亚洲值得信赖的在线赌博网站，自从正式投入服务至今最完整和最新的在线赌博游戏。 我们有一个简单的使命和愿景是提供诚实和信任的服务。',
        "aboutUs2": "除了体育游戏和真人娱乐,我们还与许多主要娱乐公司合作。因此,始终确保提供最优质的娱乐博彩服务和产品。不仅带来了信誉良好且专业的博彩市场。还有一个多样化的游乐场,为每个人带来最好的选择。",
        "aboutUs3": '最热门的投注产品。体育博彩领域，包括足球、篮球、排球、网球比赛,...非常迷人。玩家参与投注，观看世界上最大的赛季的激烈比赛。通过准确的赔率，有吸引力的回扣优惠满足您对投注的热情。',
        "aboutUs4": '与传统的赌场赌桌不同,今天我们也有真人娱乐场。在Mago 注册帐户后，玩家可以与辣妹一起加入真人娱乐场。您将通过直播屏幕与真人一起加入赌桌。不仅增加了吸引力和趣味性，还为玩家创造了更多的真实性和专业性',
        "aboutUs5": 'Mago最吸引人的娱乐产品之一是老虎机游戏。我们提供来自全球主要游戏制造商的数千种老虎机。提供具有不同颜色,内容和奖品的多样化体验。',
        "aboutUs6": '除了真人娱乐,Mago仍然提供具有醒目 3D 图形的赌博游戏。来自传统的棋牌游戏，游戏如龙虎、百家乐、二十一点,...',
        "aboutUs7": '捕鱼是一个非常熟悉的游戏,既具有很高的娱乐性,又有助于赚取额外的钱。来到Mago,捕鱼变成了具有更多独特内容和图形的多样性。',
        "aboutUs8": '拥有库拉索GCB颁发的合法执照。注册于威廉斯塔德,是受国际认可的合法公司因此,在这里参与投注和赌场的玩家不必担心合法性。Mago配备了先进的技术设备,采用专业的安全系统。因此,玩家的所有信息都将是安全的，不会暴露在外部。如果有未经授权的第三方,我们也会及时发现并防止。另外我们始终拥有最专业的客户服务人员。确保 24/7 全天候支持并回答您的所有问题。这种优势也是我们在向国际友人介绍Mago时最有信心的。因此,在需要时,请联系客服人员以获取建议和答案。我们希望您能更好地了解我们。不要忘记。注册一个Mago 帐户,以便能够参与这个令人兴奋的投注游乐场',
        "aboutUs2T": "游戏产品",
        "aboutUs3T": "体育游戏",
        "aboutUs4T": "真人娱乐 ",
        "aboutUs5T": "老虎机游戏 ",
        "aboutUs6T": "棋牌游戏 ",
        "aboutUs7T": "捕鱼游戏 ",
        "aboutUs8T": "Mago娱乐城的优势 ",
        "my": 'của tôi',
        "active": 'Hoạt động',
    }
}
