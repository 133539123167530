module.exports = {  //印尼
    common: {
        lang: 'Indonesia',
        login1: 'Sudah memiliki akun？',
        register: 'daftar',
        logout: 'Keluar',
        tip: 'petunjuk',
        logintip: 'Silakan login terlebih dahulu',
        set: 'Mendirikan',
        amount: "keseimbangan",
        close: "penutup",
        ok: "Tentu",
        capitalPass: 'Kata sandi dana',
        capitalPassinput: 'Silakan masukkan password transaksi dana',
        realNameInput: 'Silakan masukkan nama kartu bank',
        huioneNameInput: 'Silakan masukkan nama huione',
        huioneName: 'nama huione',
        nicknameInput: 'Silakan masukkan nama panggilan',
        usertip: 'Pembayaran berhasil',
        tibifirst: "Detail",
        message: "informasi",
        service: '7/24 jam',
        ads: 'Promosi',
        aboutus: 'tentang Kami',
        pleaseselectService: "Silakan pilih metode deposit",
        pleaseselectCurrency: "Silakan pilih jenis mata uang",
        currencyType: 'mata uang',
        pleaseselectService1: "Silakan pilih metode penarikan",
        pleaseselectService2: "Silakan pilih metode transfer",
        tabbar1: 'Jelajahi',  //
        tabbar2: 'kota hiburan',  //
        tabbar3: 'slip taruhan',  //
        tabbar4: 'Olahraga',  //
        tabbar5: 'Chat',  //
        date: 'Silakan pilih jangka waktu',
        date1: 'jumlah total pendaftaran',
        date2: 'Total menang atau kalah',
        date3: 'total volume taruhan',
        date4: 'ID anggota',
        date5: 'Volume taruhan anggota',
        date6: 'Anggota menang atau kalah',
        date7: 'Total taruhan yang valid',
        date8: 'Taruhan sah anggota',
        date9: 'kerjasama bisnis',
        date10: 'ID pembayaran :',
        exchange: 'Penukaran poin',

        notice23: 'Pengingat hangat: Saat ini pemain hanya dapat mentransfer uang ke rekening agen, perlu diketahui !!',
        categorySport: 'Pendidikan Jasmani',
        categoryLive: 'orang sungguhan',
        categorySlot: 'mesin slot',
        categoryFish: 'penangkapan ikan',
        categoryBingo: 'Lotere',
        categoryPoke: 'catur dan kartu',
        gender: 'jenis kelamin',
        birthday: 'Hari ulang tahun',
        nickname: 'nama panggilan',
        male: 'pria',
        female: 'perempuan',
        name: 'Nama',
        usdt: 'USDT',
        usd: 'Dolar',
        exchangeAll: 'semua',
        gOut: 'transfer keluar',
        goIn: 'transfer masuk',
        meamount: 'keseimbangan',
        mebonus: 'integral',
        agSport: 'permainan',
        sbSport: 'Jumlah pembayaran',
        ibSport: 'Sukan BTI',
        dgLive: 'Ditjen orang sungguhan',
        agLive: 'AG orang sungguhan',
        gbLive: 'PP orang sungguhan',
        E0Live: 'EVO orang sungguhan',
        wcLive: 'WM orang sungguhan',
        oneKey: 'Penyelesaian sekali klik'

    },
    uc: {
        login: {
            login: 'Masuk',
            usertip: 'Silakan masukkan nomor ponsel',
            pwdtip: 'Silakan masukkan kata sandi',
            validatecodeload: 'Memuat kode verifikasi',
            validatemsg: 'Silakan selesaikan verifikasi terlebih dahulu',
            forget: 'lupa kata sandinya?',
            loginvalidate: 'Silakan masukkan nomor rekening',
            pwdvalidate1: 'Silakan masukkan kata sandi',
            pwdvalidate2: 'Panjang kata sandi tidak boleh kurang dari 6 karakter',
            success: 'Masuk berhasil',
            goregister: "daftar",
            getlostpwd: "lupa kata sandinya",
            nouser: "Pengguna tidak ada",
            normaluser: 'Rekening pihak lain tidak mendukung transfer',
            slogan: 'Salah satu platform taruhan paling tepercaya di Asia',
            toSetting: 'Buka pengaturan',
            settinghuione: 'Silakan atur nama pengguna huione terlebih dahulu',
            settingbank: 'Harap atur nama pengguna kartu bank Anda terlebih dahulu'
        },
        regist: {
            smscode: 'Kode verifikasi SMS',
            sendcode: 'Kirim kode verifikasi',
            resendcode: 'Kirim ulang(',
            pwd: 'Kata sandi masuk',
            repwd: 'Konfirmasi Kata Sandi',
            agreement: 'Saya telah membaca dan setuju',
            userprotocol: 'Perjanjian Pengguna',
            regist: 'daftar',
            success: 'Pendaftaran berhasil',
            teltip: 'Silakan masukkan nomor ponsel',
            telerr: 'Format nomor ponsel salah, silakan masukkan kembali.',
            emailtip: 'Silakan masukkan email Anda',
            emailerr: 'Kata sandi salah, silakan masukkan kembali',
            confirmpwdtip: 'Silakan masukkan kata sandi konfirmasi',
            confirmpwderr: 'Kedua input kata sandi tidak konsisten!',
            telregist: 'Registrasi ponsel',
            emailregist: 'Pendaftaran email',
            smscodetip: 'Silakan masukkan kode verifikasi SMS',
            pwdtip: 'Silakan masukkan kata sandi masuk Anda',
            pwdmsg: 'Panjang kata sandi tidak boleh kurang dari 6 karakter',
            telno: 'nomor telepon',
            email: 'Alamat email',
            agreementtip: 'Silakan klik untuk menyetujui',
            modaltitle: 'Harap verifikasi',
            promotion: 'ID Agen (opsional)',
            china: 'Cina Sederhana',
            singapore: 'English',
            thailand: 'แบบไทย',
            vietnam: 'Tiếng Việt',
            india: 'Indonesia',
            japan: 'にほんご ',
            korea: '한국어',
            hk: '繁體中文',
            malaysia: 'Melayu',
            taiwan: '繁體中文',
            cambodia: 'កម្ពុជា។',
            uk: 'English'
        },
        forget: {
            newpwd: 'Silakan masukkan kata sandi baru',
            confirmpwd: 'Harap konfirmasi kata sandi lagi',
            confirmpwd11: 'ID pengguna pembayaran',
            save: 'kirim',
            pwdvalidate1: 'Silakan masukkan kata sandi konfirmasi',
            pwdvalidate2: 'Kedua input kata sandi tidak konsisten!',
            resettelpwd: 'Kata sandi masuk',
            resetemailpwd: 'Kata sandi dana',
            newpwdtip: 'Silakan masukkan kata sandi baru',
            pwdvalidate3: 'Panjang kata sandi tidak boleh kurang dari 6 karakter',
            smscode: 'Silakan masukkan kode verifikasi',
            teltip: 'Silakan masukkan nomor ponsel',
            smscodetip: 'Silakan masukkan kode verifikasi SMS',
            email: 'Surat',
            emailcode: 'Kode verifikasi email',
            emailtip: 'Silakan masukkan email Anda',
            emailcodetip: 'Silakan masukkan kata sandi dana',
            resetpwdsuccess: 'Penyetelan ulang kata sandi berhasil',
            addresstip: 'Silakan masukkan alamat penarikan',
            addresstip21: 'Silakan masukkan ID pengguna pembayaran',
            addresstip221: 'Silakan masukkan nomor rekening',
            addresstip222: 'Silakan masukkan nomor kartu bank pengguna penerima',
            emailcodetip1: 'Silakan atur kata sandi dana',
            emailcodetip11: 'Silakan tetapkan alamat penarikan',
            walletaddress: 'alamat dompet',
            huioneaddress: ' huione nomor rekening',
            walletaddress1: 'Alamat pembayaran',
            walletaddress2: 'Jumlah aktual yang diterima',
            walletaddress21: 'Jumlahnya harus lebih besar dari 0',
            wallet: 'dompet',
            userID: 'ID Akun',
            upload: '+ Unggah kredensial',
            addresstip31: 'Nama pembayar',
            addresstip41: 'Nomor rekening pembayar',
            addresstip311: 'Nama',
            addresstip321: 'Silakan masukkan nama',
            personal: 'informasi pribadi'
        },
        finance: {
            center: 'Pusat Pribadi',
            personalassets: 'jaringan',
            swapassets: 'alamat',
            swapassets1: 'akun',
            recordetail: 'Detail transaksi',
            tradetail: 'keseimbangan',
            invitingmining: 'Jumlah',
            charge: 'deposito',
            pickup: 'Tarik uang',
            copy: 'menyalin',
            choose: 'Memilih',
            cards: 'kartu bank',
            copysuccess: 'Berhasil disalin',
            copyfailed: 'Penyalinan gagal',
            notice: "Biaya layanan jaringan/biaya penanganan dibayar oleh anggota",
            notice1: "Setiap anggota memiliki alamat isi ulang USTD yang independen. Setelah transfer berhasil, sistem akan secara otomatis mentransfer sejumlah uang ke rekening. Jika transfer tidak sampai dalam waktu lama, silakan hubungi layanan pelanggan di beranda untuk konsultasi.",
            notice11: 'Setelah transfer berhasil, sistem akan secara otomatis mengkredit akun. Jika akun tidak sampai dalam waktu lama, silakan hubungi layanan pelanggan beranda untuk konsultasi tepat waktu.',
            transfee: "biaya penanganan",
            emailcodetip: 'Kata sandi dana',
            gamedetail: 'Rekor taruhan',
            gametype: 'jenis permainan',
            game0: 'permainan WALL-E',
            game81: 'Video game langsung',
            game82: 'permainan papan',
            game83: 'permainan video',
            game84: 'permainan olahraga',
            game85: 'permainan mesin slot',
            game86: 'permainan lotere',
            game87: 'permainan memancing',
            game88: 'permainan poker',
            game89: 'permainan mini',
            game90: 'Permainan lainnya',
            game91: 'disk warna video',
            game92: 'Bakarat',
            game93: 'harimau naga',
            game94: 'Sic Bo',
            game95: 'Sangat luar biasa',
            game96: 'Niuniu',
            game97: 'rolet Amerika',
            game98: 'Bunga Emas Goreng',
            game99: 'Hidangan berwarna',
            game100: 'Selikuran',
            game101: 'Sangat luar biasa',
            game102: 'Andabaha',
            game103: 'Fantan',
            game104: 'Sangong',
            fbbet: 'Taruhan FB',
            bet: 'Jumlah taruhan',
            validBet: 'bertaruh',
            profit: 'Laba rugi',
            addUaddress: 'Manajemen kartu',
            addaddress: 'Tambahkan informasi penarikan',
            trans: 'transfer',
            agentdata: 'data proksi',
            agentpage: 'kerjasama bisnis',
            timezone: 'zona waktu',
            agentapply: 'Lamar sekarang',
            agentnotice: 'Silakan melamar agen terlebih dahulu',
            datapage: 'data proksi',
            promotion: 'Promosi agensi',
            promotion1: 'Kode promosi agen',
            promotion2: 'Simpan kode QR',
            promotion3: 'Tautan promosi',
            notice12: "Setelah informasi penarikan berhasil diatur, hanya dapat diubah dengan menghubungi layanan pelanggan.",
            notice123: "Silakan masukkan informasi pembayar",
            notice1234: "Operasi berhasil dan akan diproses tepat waktu, harap tunggu.",
            notice12345: "Silakan masukkan jumlah yang valid",
            xx1: 'Isi ulang pesanan',
            xx2: 'Tarik pesanan',
            xx3: 'negara',
            xx4: 'Sedang ditinjau',
            xx5: 'kesuksesan',
            xx6: 'gagal',
            xx7: 'Nama pengisi ulang',
            xx8: 'Isi ulang akun',
            xx9: 'Nama penarikan',
            xx10: 'Tarik akun',
            xx11: 'Komentar',
            xx12: 'Waktu pembuatan tagihan',
            xx13: 'Nomor pengguna',
            xx14: 'tidak ada data lagi',
            xx15: 'Perpindahan anggota',
            xx17: 'Transfer dan tarik tunai',
            xx18: 'Transfer dan isi ulang',
            xx19: 'Isi ulang daring',
            xx20: 'Isi ulang secara manual',
            xx21: 'Penarikan U-Shield',
            xx22: 'Penarikan manual',
            xx23: 'Poin permainan',
            xx24: 'Skor permainan',
            xx25: 'bonus',
            xx26: 'Skor tinggi',
            xx27: 'Poin dikurangi',
            xx28: 'Daftarkan amplop merah',
            xx29: 'Nomor pesanan',
            xx30: 'jam perdagangan',
            xx31: 'Saldo setelah transaksi',
            xx32: 'Rabat permainan',
            xx33: 'Pengguna yang terhormat',
            xx34: 'Anggota downline',
            xx35: 'Anggota baru terdaftar bulan ini',
            xx36: 'Anggota aktif bulan ini',
            xx37: 'Kemenangan atau kekalahan bersih bulan ini',
            xx38: 'Rasio rabat',
            xx39: 'Komisi bulan ini',
            xx40: 'Kode promosi',
            xx41: 'nama domain khusus',
            xx42: 'Daftar anggota offline',
            xx43: 'formulir komisi',
            xx44: 'Cari akun anggota',
            xx45: 'akun anggota',
            xx46: 'Waktu masuk terakhir',
            xx47: 'Waktu pendaftaran',
            xx48: 'Komisi untuk periode ini',
            xx49: 'Total menang atau kalah',
            xx50: 'Total poin rabat',
            xx51: '彩金',
            xx52: 'Biaya penurunan nilai arus kas',
            xx53: 'Biaya penanganan arus kas',
            xx54: 'Komisi ekstra VIP',
            xx55: 'Bonus VIP',
            xx56: 'Rasio komisi',
            xx57: 'Detail tagihan',
            xx58: 'Komisi untuk periode ini',
            xx59: 'Biaya platform',
            xx60: 'Nomor rekening pembayaran',
            xx61: 'cam isi ulang',
            xx62: 'cam Tarik uang tunai',
            xx63: 'Pembayaran',
            xx64: 'Pembayaran berhasil',
            xx65: 'Pembayaran gagal',
            xx66: 'Waktu penyelesaian tagihan',
            xx67: 'Metode isi ulang',
            xx68: 'Mata uang',
            xx69: 'Metode penarikan',
            platform: 'Platform permainan',
            bitStatus: 'Status taruhan',
            xx70: 'Taruhan yang sah',
            xx71: 'Turnamen sedang berlangsung',
            xx72: 'Taruhan tidak valid',
            xx73: 'waktu mulai',
            xx74: 'waktu akhir',
            xx75: 'Nomor catatan',
            xx76: 'Nomor pesanan',
            xx77: 'ID Pemain',
            xx78: 'nomor meja',
            xx79: 'hasil permainan',
            xx80: 'Nomor pemain',
            xx81: 'Pilih bahasa',
            xx82: 'Selamat datang di pusat layanan pelanggan',
            xx83: 'Hai, Pelanggan yang Terhormat',
            xx84: 'Layanan pelanggan Khmer',
            xx85: 'Layanan pelanggan Vietnam',
            xx86: 'Layanan pelanggan Cina',
            xx87: 'Layanan pelanggan bahasa Inggris',
            xx88: 'Nama kartu bank',
            xx89: 'Nomor rekening kartu bank',
            xx91: 'Nama bank',
            xx92: 'Pelayanan dedicated line 7x24 jam, care first',
            xx93: 'Tambahkan kartu bank',
            xx94: 'Tambahkan alamat penarikan',
            xx95: 'Apakah Anda yakin ingin kembali ke lobi? Jika uang dalam permainan tidak dikembalikan dengan benar, silakan gunakan fungsi pengembalian sekali klik di halaman saya',
            xx96: 'Nama liga',
            xx97: 'tim tuan rumah',
            xx98: 'tim kunjungan',
            xx99: 'skor',
            xx100: 'Semua informasi diperlukan',
            xx101: 'Tentang Jbbet',
            xx102: 'Jbbet adalah tujuan perjudian dan hiburan favorit banyak orang. Ini adalah taman bermain yang sangat dihargai di antara situs taruhan terkemuka dan berkualitas saat ini. Jbbet tidak hanya menikmati reputasi tinggi di Asia Tenggara, tetapi juga menempati posisi penting di pasar perjudian seluruh pasar Asia.',
            xx103: 'Kelahiran Jbbet',
            xx104: 'Meskipun popularitasnya baru-baru ini, Jbbet telah beroperasi di pasar Asia selama bertahun-tahun. Jbbet didirikan pada tahun 2005 dan resmi beroperasi di Kamboja. Terima kasih kepada PAGCOR atas kerjasamanya dalam penerbitan izin operasional. Bandar taruhan ini memiliki banyak permainan judi yang populer di kalangan pemainnya.',
            xx105: 'Produk dan layanan Jbbet',
            xx106: 'Selain permainan olahraga dan hiburan langsung, kami juga bekerja sama dengan banyak perusahaan hiburan besar. Oleh karena itu, kami selalu memastikan layanan dan produk hiburan dan perjudian dengan kualitas terbaik. Tidak hanya menghadirkan pasar taruhan yang bereputasi dan profesional. Ada juga taman bermain yang beragam, menghadirkan pilihan terbaik untuk semua orang.',
            xx107: 'permainan olahraga',
            xx108: 'Produk taruhan paling populer. Bidang taruhan olahraga, termasuk sepak bola, bola basket, bola voli, pertandingan tenis,...sangat menarik. Pemain berpartisipasi dalam taruhan dan menyaksikan aksi musim terbesar di dunia. Puaskan hasrat Anda untuk bertaruh dengan odds yang akurat dan penawaran rabat yang menarik.',
            xx109: 'hiburan langsung',
            xx110: 'Tidak seperti meja kasino tradisional, saat ini kami juga memiliki kasino langsung. Setelah mendaftarkan akun di Jbbet, pemain dapat bergabung dengan kasino langsung dengan gadis-gadis seksi. Anda akan bergabung dalam meja dengan orang sungguhan melalui layar langsung. Tidak hanya meningkatkan daya tarik dan kesenangan, tetapi juga menciptakan lebih banyak keaslian dan profesionalisme bagi para pemain',
            xx111: 'permainan mesin slot',
            xx112: 'Salah satu produk hiburan Jbbet yang paling menarik adalah permainan mesin slot. Kami menawarkan ratusan mesin slot dari produsen game besar di seluruh dunia. Memberikan pengalaman beragam dengan warna, konten, dan hadiah berbeda.',
            xx113: 'permainan papan',
            xx114: 'Selain hiburan live, Jbbet juga menawarkan permainan judi dengan grafis 3D yang menarik. Dari permainan papan dan kartu tradisional, permainan seperti Dragon Tiger, Baccarat, Blackjack,... ',
            xx115: 'permainan memancing',
            xx116: 'Memancing adalah permainan yang sangat familiar, sangat menghibur dan berguna untuk menghasilkan uang tambahan. Hadir di Jbbet, memancing menjadi lebih beragam dengan konten dan grafis yang lebih unik.',
            xx117: 'Keuntungan dari Jbbet',
            xx118: 'Memiliki lisensi resmi yang dikeluarkan oleh MGA Malta Eropa. Terdaftar di British Virgin Islands, ini adalah perusahaan legal yang diakui secara internasional. Oleh karena itu, pemain yang berpartisipasi dalam taruhan dan kasino di sini tidak perlu khawatir tentang legalitas. Jbbet dilengkapi dengan peralatan teknis canggih dan mengadopsi sistem keamanan profesional. Oleh karena itu, semua informasi pemain akan aman dan tidak akan terekspos ke dunia luar. Jika ada pihak ketiga yang tidak berkepentingan, kami akan segera mendeteksi dan mencegahnya. Selain itu, kami selalu memiliki staf layanan pelanggan yang paling profesional. Pastikan dukungan 24/7 dan jawaban atas semua pertanyaan Anda. Keunggulan inilah pula yang membuat kami paling percaya diri saat memperkenalkan Jbbet kepada teman-teman internasional. Oleh karena itu, silakan menghubungi layanan pelanggan untuk mendapatkan saran dan jawaban bila diperlukan. ',
            xx119: 'Kami harap Anda dapat mengenal kami lebih baik. Jangan lupa. Daftarkan akun Jbbet untuk bisa mengikuti arena taruhan seru ini',
            xx120: 'Mata uang ini tidak didukung. Silakan buka halaman dompet untuk menukar atau mengganti mata uang.',
            xx121: 'Nama pembayar',
            xx122: 'Alamat USDT (TRC20)',
            xx123: 'Manajemen alamat',
            xx124: 'semua',
            xx125: 'isi ulang',
            xx126: 'Tarik uang tunai',
            xx127: 'Membatalkan',
            xx128: 'Melamar',
            xx129: 'Dikonfirmasi',
            xx130: 'Diberhentikan',
            xx131: 'Olahraga FB',
            xx1311: 'video FB',
            xx132: 'Catatan permainan',
            xx133: 'Lotere',
            xx134: 'Tertutup',
            xx135: 'membuka',
            xx136: 'Membatalkan',
            xx137: 'Kemarin',
            xx138: 'Hari ini',
            xx139: '7 hari terakhir',
            xx140: 'Hampir 30 hari',
            xx141: 'taruhan tunggal',
            xx142: 'Mengunjungi',
            xx143: 'juara',
            xx144: 'tanggal pilihan Anda',
            xx145: 'Layanan pelanggan Mago',
            xx146: 'bersembunyi',
            xx147: 'Uji isi ulang',
            xx148: 'Taruhan permainan',
            xx149: 'Olahraga CMD',
            xx150: 'Olahraga Saba',
            xx151: 'Leyou',
            xx152: 'tanggal pertandingan',
            xx153: 'Perubahan poin',
            xx154: 'Penukaran USDT',
            xx155: 'Layanan pelanggan Thailand',
            xx156: 'layanan pelanggan indonesia',
            xx157: 'Mesin slot JDB',
            xx158: 'Mesin slot PG',
            xx159: 'Mesin slot PP',
            xx160: 'Mesin slot NT',
            xx161: 'memancing JDB',
            xx162: 'Hai, selamat datang atas kedatangan Anda',
            xx163: 'Tidak ada akun？',
            xx164: 'mata uang saya',
            xx165: 'menukarkan',
            xx166: 'Daftarkan amplop merah',
            xx167: 'Penyesuaian akun sistem CMD',
            xx168: 'pembelian permainan',
            xx169: 'Pembelian permainan',
            xx170: 'jackpot progresif',
            xx171: 'bonus',
            xx172: 'Batalkan taruhan',
            xx173: 'Taruhan kembalikan',
            xx174: 'penyelesaian permainan',
            xx175: 'Taruhan permainan',
            xx176: 'Pengembalian dana jika penarikan gagal',
            xx177: 'Perpindahan anggota',
            xx178: 'Poin dikurangi',
            xx179: 'Pengurangan poin bonus',
            xx180: 'membuat poin',
            xx181: 'bonus',
            xx182: 'tripartit',
            xx183: 'Huione',
            xx184: 'uDUN',
            xx185: 'petunjuk',
            xx186: 'Penarikan manual',
            xx187: 'Huione',
            xx188: 'withdraw',
            xx189: 'Silakan pilih bank',
            xx190: 'dari：',
            xx191: 'ke：',
            xx192: 'Poin dan ',
            xx193: 'menang',
            xx194: 'menang',
            xx195: 'kehilangan',
            xx196: 'kehilangan',
            xx197: 'datar',
            xx198: 'Gelisah',
            xx199: 'Jumlah pertukaran tidak boleh kurang dari 1',
            xx200: 'Nama',
            xx201: 'Masukkan kata sandi',
            xx202: 'Tarik kata sandi',
            xx203: 'kembali',
            xx204: 'menyimpan',
            xx205: 'Belum ada catatan',
            xx206: 'Penukaran poin',
            xx207: 'Cacat Eropa',
            xx208: 'Disk Hong Kong',
            xx209: 'masakan Melayu',
            xx210: 'Handicap Indonesia',
            xx211: 'dapat dimenangkan',
            xx212: 'Dibuat',
            xx213: 'Mengonfirmasi',
            xx214: 'Ditolak',
            xx215: 'Dibatalkan',
            xx216: 'Dikonfirmasi',
            xx217: 'Mapan',
            xx218: 'poin saya',
            xx219: ' Rasio pertukaran：',
            xx220: ' integral',
            xx221: ' Poin diperoleh',
            xx222: ' tingkat vip',
            xx223: '彩金扣分',
            xx226: '体育结算'
        },
    },
    foot: {
        t1: "mago Berkomitmen pada perjudian yang bertanggung jawab, untuk informasi lebih lanjut silakan kunjungi",
        "t2": 'Mago.game dimiliki dan dioperasikan oleh Jinbei Group, perusahaan terdaftar: Altacore N.V. Nomor registrasi: 151002, alamat terdaftar: Dr. H. Fergusonweg 1, Gaito, Curacao. Silakan hubungi kami di support@Mago.game. Dilisensikan oleh Curacao Gaming Control Board dengan nomor lisensi OGL/2023/109/0075 per 1 Juli 2024. Agen pembayarannya adalah: Asia Payment Services Singapore.',
        t3: "mendukung",
        t4: "bekerja samaMitra",
        t5: "media berita",
    },
    new: {
        "ting": "aula",
        "magoRecommendation": "mago direkomendasikan",
        "slots": "mesin slot",
        "liveCasino": "kasino langsung",
        "gameShow": "permainan mini",
        "tableGames": "permainan meja",
        "fishing": "penangkapan ikan",
        "bingo": "Bingo",
        "newGame": "permainan baru",
        "liveCasinoTxt": "kasino langsung",
        "searchGamePlaceholder": "Cari permainan",
        "recharge": "isi ulang",
        "withdraw": "Tarik uang tunai",
        "favorites": "Favorit",
        "recentGames": "Sejarah permainan terkini",
        "vipClub": "Klub VIP",
        "alliancePlan": "Program Afiliasi",
        "gamesTitle": "permainan",
        "sports": "olahraga",
        "gameProvider": "penyedia permainan",   //
        "languageSelection": "Pemilihan bahasa",
        "timezone": "zona waktu",//
        "onlineSupport": "Dukungan daring",
        "aboutUs": "tentang Kami",
        "antiMoneyLaundering": "Kebijakan Anti Pencucian Uang",
        "license": "lisensi",
        "responsibleGambling": "Perjudian yang Bertanggung Jawab",
        "termsOfService": "Ketentuan Layanan",
        "logout": "berhenti",
        "fbLiveCasino": "FB Video langsung",
        "loadingText": "memuat",
        "recommendation": "mago direkomendasikan",
        "myFavorites": "koleksi saya",
        "gameHistory": "Catatan permainan",
        "noData": "Belum ada datanya",
        "noFavoritesData": "Belum ada pengumpulan data",
        "noGamesData": "Belum ada data permainan",
        "gameProviderPlaceholder": "penyedia permainan",
        "exclusiveAlliancePlan": "Program afiliasi eksklusif",
        "joinMogo": "Bergabunglah dengan program afiliasi mogo dan dapatkan uang dengan merekomendasikan merek kami! Jadilah mitra kami dan dapatkan hingga 55% keuntungan Anda. Anda akan memperoleh persentase pendapatan dari pemain yang direkomendasikan. Mari kita berbagi kesuksesan bersama",
        "becomePartner": "Menjadi mitra",
        "exclusiveVIPExperience": "Pengalaman VIP yang tak tertandingi",
        "unlockExclusiveBenefits": "Dapatkan manfaat eksklusif dan dapatkan bonus yang dapat Anda tarik secara instan tanpa syarat apa pun。",
        "register": "daftar",
        "vipRankingSystem": "Sistem peringkat VIP Mago",
        "bettingAmount": "Jumlah taruhan",
        "vipClubBenefits": "Manfaat Klub VIP Mago",
        "yourVIPProgress": "Kemajuan VIP Anda",
        "currentLevel": "tingkat saat ini",
        "currentLevelValue": "V",
        "expectedWeeklyMonthlyBonus": "Anda dapat mengharapkan bonus mingguan dan bulanan berdasarkan volume permainan Anda. Semakin banyak Anda bermain, semakin banyak bonus yang Anda dapatkan。",
        "exclusiveVIPServiceRepresentative": "Dapatkan Perwakilan Layanan VIP berdedikasi yang akan mendukung dan memenuhi kebutuhan taruhan Anda。",
        "recentGamePerformanceBonus": "Sial? Stake memberikan dana tambahan berdasarkan kerugian Anda setiap kali Anda naik level。",
        "levelUpBonus": "Dapatkan bonus setelah mencapai setiap level berikutnya. Semakin tinggi level yang dicapai, semakin besar bonus peningkatannya.",
        "bespoke": "Bekerja samalah dengan Perwakilan Layanan VIP khusus Anda untuk menyesuaikan manfaat dengan kebutuhan taruhan Anda。",
        "bronze": "Bronze",
        "silver": "Silver",
        "gold": "Gold",
        "platinum": "kartu platina I-III",
        "inpWithPwd": "Silakan masukkan kata sandi dana",
        "inpConfirmPwd": "Masukkan kata sandi konfirmasi dana",
        "pwdMismatch": "Dua kata sandi tidak konsisten",
        "nameRequired": "Nama tidak boleh kosong",
        "pwdLabel": "Kata sandi dana",
        "confirmPwdLabel": "Konfirmasikan kata sandi dana",
        "fillPrompt": "Silakan isi sesuai petunjuknya",
        "saveContinue": "Simpan dan lanjutkan",
        "confirmDetails": "Konfirmasikan detail Anda",// kaish
        "fillDetails": "Silakan isi detail Anda dan konfirmasikan identitas Anda untuk membuka layanan tambahan. Informasi yang diberikan bersifat pribadi dan aman。",
        "realNameLabel": "nama asli",
        "birthdayLabel": "ulang tahunmu",
        "promotionTitle": "promosi",
        "eventTitle": "AS Terbuka",
        "bonusTitle": "Bonus ganda bola as",
        "readMore": "Baca selengkapnya",
        "experienceNow": "Cobalah sekarang",
        "viewAll": "Lihat semuanya",
        "playing": "orang-orang bermain",
        "cancel": "Membatalkan",
        "start": "awal",
        "login": "Login",
        "collect": "mengumpulkan",
        "today": "Hari ini",
        "win": "kemenangan",
        "draw": "flat",
        "lose": "lose",
        "setWallet": "Ayo siapkan dompet Anda dan mulai bermain！",
        "goSetPwd": "Buka pengaturan",
        "pointsSystem": "sistem poin",
        "Browse": "Peramban",
        "Casino": "Kasino",
        "Bets": "slip taruhan",
        "Sports": "Olahraga",
        "Chat": "Chat",//
        "firstTelegram": "TELEGRAM miniAPP",
        "miniProgramCasino": "kasino daring",
        "followTelegram": "Ikuti saluran Telegram resmi",
        "trialBonusChannel": "Terima bonus percobaan",
        "enterNow": "Masuk sekarang",
        "announcement": "pengumuman",
        "magoJoinFB": "FB Live Kasino",
        "exclusiveAnchor": "Gaya desain baru",
        "t4": "Memberi Anda pengalaman berbeda",
        "playNow": "Datang dan mainkan sekarang",
        "aboutUs1": 'Mago娱乐城属于金贝集团所属经营，Mago是亚洲值得信赖的在线赌博网站，自从正式投入服务至今最完整和最新的在线赌博游戏。 我们有一个简单的使命和愿景是提供诚实和信任的服务。',
        "aboutUs2": "除了体育游戏和真人娱乐,我们还与许多主要娱乐公司合作。因此,始终确保提供最优质的娱乐博彩服务和产品。不仅带来了信誉良好且专业的博彩市场。还有一个多样化的游乐场,为每个人带来最好的选择。",
        "aboutUs3": '最热门的投注产品。体育博彩领域，包括足球、篮球、排球、网球比赛,...非常迷人。玩家参与投注，观看世界上最大的赛季的激烈比赛。通过准确的赔率，有吸引力的回扣优惠满足您对投注的热情。',
        "aboutUs4": '与传统的赌场赌桌不同,今天我们也有真人娱乐场。在Mago 注册帐户后，玩家可以与辣妹一起加入真人娱乐场。您将通过直播屏幕与真人一起加入赌桌。不仅增加了吸引力和趣味性，还为玩家创造了更多的真实性和专业性',
        "aboutUs5": 'Mago最吸引人的娱乐产品之一是老虎机游戏。我们提供来自全球主要游戏制造商的数千种老虎机。提供具有不同颜色,内容和奖品的多样化体验。',
        "aboutUs6": '除了真人娱乐,Mago仍然提供具有醒目 3D 图形的赌博游戏。来自传统的棋牌游戏，游戏如龙虎、百家乐、二十一点,...',
        "aboutUs7": '捕鱼是一个非常熟悉的游戏,既具有很高的娱乐性,又有助于赚取额外的钱。来到Mago,捕鱼变成了具有更多独特内容和图形的多样性。',
        "aboutUs8": '拥有库拉索GCB颁发的合法执照。注册于威廉斯塔德,是受国际认可的合法公司因此,在这里参与投注和赌场的玩家不必担心合法性。Mago配备了先进的技术设备,采用专业的安全系统。因此,玩家的所有信息都将是安全的，不会暴露在外部。如果有未经授权的第三方,我们也会及时发现并防止。另外我们始终拥有最专业的客户服务人员。确保 24/7 全天候支持并回答您的所有问题。这种优势也是我们在向国际友人介绍Mago时最有信心的。因此,在需要时,请联系客服人员以获取建议和答案。我们希望您能更好地了解我们。不要忘记。注册一个Mago 帐户,以便能够参与这个令人兴奋的投注游乐场',
        "aboutUs2T": "游戏产品",
        "aboutUs3T": "体育游戏",
        "aboutUs4T": "真人娱乐 ",
        "aboutUs5T": "老虎机游戏 ",
        "aboutUs6T": "棋牌游戏 ",
        "aboutUs7T": "捕鱼游戏 ",
        "aboutUs8T": "Mago娱乐城的优势 ",
        "my": 'milikku',
        "active": 'Aktivitas',
    },
}
