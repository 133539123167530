const throttle =  {
    inserted(el, binding) {
        let throttleTime = binding.value // 节流时间
        if (!throttleTime) { // 用户若不设置节流时间，则默认2s
            throttleTime = 2000
        }

        el.addEventListener('click', e => {
            el.classList.add('is-disabled');
            el.disabled = true;
            setTimeout(() => {
                el.disabled = false;
                el.classList.remove('is-disabled');
            }, throttleTime)
        })
    }
}

const install = Vue => {
    Vue.directive('throttle', throttle);
}
export default {
    install
}
