module.exports = {
    common: {
        lang: "한국인",
        login1: '이미 계정이 있나요? 로그인！',
        register: '등록',
        logout: '로그아웃',
        tip: '유의',
        logintip: '먼저 로그인하거나 등록하세요',
        set: '설정',
        amount: "잔액",
        close: "아웃",
        ok: "~확실히",
        capitalPass: '개인정보',
        capitalPassinput: '출금 비밀번호 설정',
        realNameInput: '은행카드이름',
        huioneNameInput: 'HUIONE이름',
        huioneName: 'HUIONE계정',
        nicknameInput: '별명',
        usertip: '송금부탁드립니다',
        tibifirst: "세부 사항",
        message: "정보",
        service: 'JBbet상담',
        ads: '프로모션',
        aboutus: '회사소개！！！',
        pleaseselectService: "방법 선택",
        pleaseselectCurrency: "나의 화폐",
        currencyType: '통화 선택',
        pleaseselectService1: "출금방법",
        pleaseselectService2: "송금 방법",
        tabbar1: '홈',
        tabbar2: '프로모션',
        tabbar3: '상담',
        tabbar4: '나의',
        date: '기간을 선택하세요.',
        date1: '다운라인 회원',
        date2: '총손익',
        date3: '총베팅 금액',
        date4: '계정',
        date5: '계정',
        date6: '승리',
        date7: '총유효한 베팅',
        date8: '유효한 베팅',
        date9: '제휴',
        date10: '회원 계정 :',
        exchange: '교환',

        notice23: '알림: 현재 플레이어는 에이전트 계정으로만 돈을 이체할 수 있습니다 !',
        categorySport: '스포츠',
        categoryLive: '카지노',
        categorySlot: '슬롯',
        categoryFish: '낚시',
        categoryBingo: '로또',
        categoryPoke: '포커',
        gender: '성별',
        birthday: '생일',
        nickname: '별명',
        male: '남성',
        female: '여성',
        name: '이름',
        usdt: 'USDT',
        usd: '달러',
        exchangeAll: '모두',
        gOut: '계좌 이체',
        goIn: '보증금',
        meamount: '잔액',
        mebonus: '보너스',
        agSport: 'CMD 体育',
        sbSport: '沙巴 体育',
        ibSport: 'BTI 体育',
        dgLive: 'DG 真人',
        agLive: 'AG 真人',
        gbLive: 'PP 真人',
        E0Live: 'EVO 真人',
        wcLive: 'WM 真人',
        oneKey: '一键归账'

    },
    uc: {
        login: {
            login: '로그인',
            usertip: '전화 번호',
            pwdtip: '비밀번호',
            validatecodeload: '확인 코드',
            validatemsg: '먼저 본인 확인을 완료해주세요.',
            forget: '비밀번호 찾기',
            loginvalidate: '계정',
            pwdvalidate1: '비밀번호',
            pwdvalidate2: '비밀번호 길이는 6자 미만일 수 없습니다',
            success: '성공적 로그인',
            goregister: "등록",
            getlostpwd: "비밀번호 찾기",
            nouser: "사용자가 존재하지 않습니다",
            normaluser: '상대방 계정은 이체를 지원하지 않습니다.',
            slogan: '亚洲最信赖博彩平台之一',
            toSetting: '개정하다',
            settinghuione: 'HUIONE계정',
            settingbank: 'HUIONE이름'
        },
        regist: {
            smscode: '확인 코드',
            sendcode: '인증 코드 보내기',
            resendcode: '재전송(',
            pwd: '비밀번호',
            repwd: '~비밀번호 확인',
            agreement: '이용약권에 동의합니다',
            userprotocol: '이용 약관',
            regist: '등록',
            teltip: '전화 번호',
            telerr: '정확한 휴대폰 번호를 입력하세요',
            emailtip: '우편',
            emailerr: '계좌명이나 출금비밀번호가 잘못되었습니다. 다시 입력해주세요',
            confirmpwdtip: '비밀번호를 반복하세요',
            confirmpwderr: '계좌명이나 출금 비밀번호에 오류가 있습니다',
            telregist: '휴대폰 등록',
            emailregist: '이메일 등록',
            smscodetip: '확인 코드',
            pwdtip: '비밀번호',
            pwdmsg: '비밀번호 길이는 6자 미만일 수 없습니다',
            telno: '전화 번호',
            email: '우편',
            agreementtip: '확인 해주세요',
            modaltitle: '확인하시기 바랍니다',
            promotion: '제휴',
            china: '简体中文',
            singapore: 'English',
            thailand: 'แบบไทย',
            vietnam: 'Tiếng Việt',
            india: 'Indonesia',
            japan: 'にほんご ',
            korea: '한국어',
            hk: '繁體中文',
            malaysia: 'Melayu',
            taiwan: '繁體中文',
            cambodia: 'កម្ពុជា។',
            uk: 'English'
        },
        forget: {
            newpwd: '새 비밀번호',
            confirmpwd: '비밀번호를 반복하세요',
            confirmpwd11: '회원 계정',
            save: '제출하다',
            pwdvalidate1: '비밀번호',
            pwdvalidate2: '계좌명이나 출금 비밀번호에 오류가 있습니다',
            resettelpwd: '비밀번호 변경하기',
            resetemailpwd: '출금 비밀번호 변경',
            newpwdtip: '새 비밀번호',
            pwdvalidate3: '비밀번호 길이는 6자 미만일 수 없습니다',
            smscode: '확인 코드',
            teltip: '전화 번호',
            smscodetip: '확인 코드',
            email: '우편',
            emailcode: '확인 코드',
            emailtip: '우편',
            emailcodetip: '결제 비밀번호',
            resetpwdsuccess: '업데이트 완료',
            addresstip: '출금 주소 등록',
            addresstip21: '회원 계정',
            addresstip221: 'HUIONE계정',
            addresstip222: '은행 계정',
            emailcodetip1: '출금 비밀번호 설정',
            emailcodetip11: '출금 주소 등록',
            walletaddress: '주소 관리',
            huioneaddress: 'HUIONE회원 계정',
            walletaddress1: '주소 관리',
            walletaddress2: '실제 받은 금액！',
            walletaddress21: '금액은 0보다 커야 합니다！',
            wallet: '지갑',
            userID: '회원 계정',
            upload: '자격 증명을 업로드하려면 클릭하세요.！',
            addresstip31: 'HUIONE이름',
            addresstip41: 'HUIONE회원 계정',
            addresstip311: '이름',
            addresstip321: '이름',
            personal: '개인정보'
        },
        finance: {
            center: '개인정보',
            personalassets: 'Jaringan penyetoran dan penarikan！',
            swapassets: '주소 관리',
            swapassets1: '회원 계정',
            recordetail: '거래 내역',
            tradetail: '잔액',
            invitingmining: '금액',
            charge: '충전',
            pickup: '출금',
            copy: '복사',
            choose: '선택하다',
            cards: '카드 관리',
            copysuccess: '성공적으로 복사되었습니다',
            copyfailed: '복사 실패',
            notice: "网络服务费/手续费由会员支付",
            notice1: "每位会员都有独立的USTD存款地址, 转账成功后系统会根据转账金额自动到账,  如遇长时间未到账请及时联系主页客服进行咨询",
            notice11: '오랫동안 계좌에 적립이 되지 않을 경우, 홈페이지 고객센터로 문의해 주시기 바랍니다.',
            transfee: "교통비",
            emailcodetip: '비밀번호 철회',
            gamedetail: '게임 기록',
            gametype: '게임 타입',
            game0: 'WL',
            game81: '라이브 카지노',
            game82: '포커',
            game83: '电子游戏',
            game84: '스포츠 경기',
            game85: '슬롯',
            game86: '로또',
            game87: '捕鱼游戏',
            game88: '扑克游戏',
            game89: '迷你游戏',
            game90: '其他游戏',
            game91: '视讯色碟',
            game92: '视讯保险百家乐',
            game100: 'WL스포츠',
            bet: '베팅 내역',
            validBet: '유효한 베팅',
            profit: '승리',
            addUaddress: '은행 계정',
            addaddress: '출금 주소 등록',
            trans: '옮기다',
            agentdata: '커미션 리포트',
            agentpage: '비즈니스 협력',
            timezone: '시간대 설정',
            agentapply: '지금 신청하세요',
            agentnotice: '대행사 신청',
            datapage: '커미션 리포트',
            promotion: '프로모션 효과',
            promotion1: 'QR코드 홍보',
            promotion2: '사진 저장',
            promotion3: '프로모션 링크',
            notice12: "코인 출금   주소가 성공적으로 설정되면 고객 서비스에 문의해야만 변경할 수 있습니다.",
            notice123: "회원 계정으로 이체",
            notice1234: "작업이 성공적으로 완료되었으며 시간 내에 처리될 예정입니다. 잠시 기다려 주세요.！",
            notice12345: "유효한 베팅",
            xx1: '재충전 주문',
            xx2: '출금주문번호',
            xx3: '상태',
            xx4: '검토중',
            xx5: '성공',
            xx6: '실패',
            xx7: '이름',
            xx8: '계정',
            xx9: '이름',
            xx10: '계정',
            xx11: '비고',
            xx12: '시간',
            xx13: '회원 계정',
            xx14: '아직 없음',
            xx15: '회원옮기다',
            xx17: '현금 이체 및 인출',
            xx18: '전송 및 재충전',
            xx19: '온라인 충전',
            xx20: '온라인 충전',
            xx21: 'U-쉴드 철수',
            xx22: '온라인 인출',
            xx23: '충전',
            xx24: '출금',
            xx25: '보너스',
            xx26: '포인트 추가',
            xx27: '차감된 포인트',
            xx28: '등록 선물',
            xx29: '슬립 번호',
            xx30: '시간',
            xx31: '거래 후 남은 금액',
            xx32: '커미션 비율',
            xx33: '친애하는 진베이 회원 여러분,',
            xx34: '다운라인 회원',
            xx35: '이번 달에 등록한 신입 회원',
            xx36: '이번 달 정회원',
            xx37: '이번 달 순 손익',
            xx38: '리베이트 비율',
            xx39: '이번달 커미션',
            xx40: 'QR코드 홍보',
            xx41: '개인 도메인 이름',
            xx42: '다운라인 회원 리스트',
            xx43: '커미션 리포트',
            xx44: '회원 계정 검색',
            xx45: '회원 계정',
            xx46: '마지막 로그온 시간',
            xx47: '등록일',
            xx48: '이번 달 커미션',
            xx49: '총손익',
            xx50: '총 리베이트',
            xx51: '보너스',
            xx52: '입출금 공제',
            xx53: '금 흐름 수수료',
            xx54: 'VIP 추가 커미션',
            xx55: 'VIP 보너스',
            xx56: '커미션 비율',
            xx57: '재무제표',
            xx58: '이번 달 커미션',
            xx59: '플랫폼 수수료',
            xx60: '계좌 인출',
            xx61: 'cam통행료',
            xx62: 'cam철회하다',
            xx63: '충전 진행 중',
            xx64: '결제 성공',
            xx65: '결제 실패',
            xx66: '주문 완료 시간',
            xx67: '입금방법',
            xx68: '화폐',
            xx69: '출금방법',
            platform: '게임 플랫폼',
            bitStatus: '상태',
            xx70: '유효한 베팅',
            xx71: '토너먼트 진행 중',
            xx72: '잘못된 베팅',
            xx73: '시작 시간',
            xx74: '종료 시간',
            xx75: '베팅 세부정보',
            xx76: '슬립 번호',
            xx77: '회원 계정',
            xx78: '테이블 번호',
            xx79: '결과',
            xx80: '게임 코드',
            xx81: '언어',
            xx82: 'JBBET 고객 서비스에 오신 것을 환영합니다',
            xx83: 'HI, 친애하는 고객',
            xx84: 'សេវាកម្មអតិថិជនខ្មែរ',
            xx85: 'Dịch vụ khách hàng người Việt ',
            xx86: '中国语客服',
            xx87: 'English customer service',
            xx88: '은행카드이름',
            xx89: '은행 계정',
            xx91: '은행 이름',
            xx92: '24 시간 상담',
            xx93: '은행 카드 추가',
            xx94: '자금의 안전을 보장하기 위해 출금 주소(TRC20)를 연결해 주세요.',
            xx95: '정말로 로비로 돌아가시겠습니까? 게임 재화를 정상적으로 반환할 수 없는 경우, 마이페이지의 원클릭 반환 기능을 이용해 주시기 바랍니다.',
            xx96: '리그 이름',
            xx97: '홈팀',
            xx98: '원정팀',
            xx99: '분수',
            xx100: '모든 정보가 필요합니다',
            xx101: '~을 위한JBbet',
            xx102: 'JBbet It is a favorite gambling and entertainment venue for many people. This is a highly regarded playground among todays top reputable and premium betting sites. JBbet not only enjoys a high reputation in Southeast Asia, but also occupies an important position in the gambling market of the entire Asian market.',
            xx103: 'The birth of JBbet',
            xx104: 'Despite its recent popularity, JBbet has been operating in the Asian market for many years. JBbet was established in 2005 and officially operates in Cambodia. Thanks to PAGCOR for its cooperation in issuing the operating license. This bookmaker has many gambling games that are popular among players.',
            xx105: 'JBbet’s products and services',
            xx106: 'In addition to sports games and live entertainment, we also cooperate with many major entertainment companies. Therefore, we always ensure that we provide the highest quality entertainment and gambling services and products. Not only does it bring a reputable and professional betting market. There is also a diverse playground, bringing the best options for everyone。',
            xx107: 'Sports competition',
            xx108: 'The most popular betting products. The world of sports betting, including football, basketball, volleyball, tennis... is fascinating. Players can place bets and watch games from the biggest season in the world. Satisfy your betting passion with accurate odds and attractive rebate offers。',
            xx109: 'Live casino',
            xx110: 'Unlike traditional casino tables, today we also have live casinos. After registering an account at JBbet, players can join the live casino with hot girls. Youll join the table with real people via a live screen. Not only does it increase attraction and fun, it also creates more authenticity and professionalism for players',
            xx111: 'Slots',
            xx112: 'JBbetOne of the most attractive entertainment products is slot machine games. We offer hundreds of slot machines from major game manufacturers around the world. Provide diverse experiences through different colors, content and prizes。',
            xx113: 'Poker',
            xx114: 'In addition to live entertainment, JBbet also offers gambling games with eye-catching 3D graphics. From traditional board and card games to dragon and tiger, baccarat, blackjack and other games,...',
            xx115: 'Fishing game',
            xx116: 'Fishing is a very familiar game that is both highly entertaining and a great way to make extra money. Come to JBbet, fishing becomes more diverse and the content and graphics are more unique。',
            xx117: 'Advantages of JBbet',
            xx118: 'Possess a legal license issued by the European Malta MGA. Registered in the British Virgin Islands, it is an internationally recognized legal company. Therefore, players participating in betting and casinos here do not have to worry about legality. JBbet is equipped with advanced technical equipment and adopts professional security system. Therefore, all player information will be safe and will not be exposed to the outside world. If there is an unauthorized third party, we will promptly detect and prevent it. In addition, we always have the most professional customer service staff. Ensure 24/7 support and answers to all your questions. This advantage is also what makes us most confident when introducing Jbbet to international friends. Therefore, please contact customer service for advice and answers when needed。',
            xx119: 'We hope you can get to know us better. do not forget. Register a JBbet account to be able to participate in this exciting betting playground',
            xx120: '이 통화는 지원되지 않습니다. 통화를 교환하거나 변경하려면 지갑 페이지로 이동하세요.',
            xx121: '아래와 같이 은행 카드 이름을 입력하세요.',
            xx122: 'USDT 주소(TRC20)',
            xx123: '주소 관리',
            xx124: '모든 기록',
            xx125: '입금 내역',
            xx126: '출금 내역',
            xx127: '취소',
            xx128: '중인',
            xx129: '이미 확인됨',
            xx130: '거부되었습니다',
            xx131: '스포츠 기록',
            xx132: '게임 기록',
            xx133: '결과',
            xx134: '끝났다',
            xx135: '아직끝',
            xx136: '취소',
            xx137: '어제',
            xx138: '오늘',
            xx139: '지난 7일',
            xx140: '지난 30일',
            xx141: '단일 베팅',
            xx142: '다중 베팅',
            xx143: '챔피언',
            xx144: '원하는 날짜',
            xx145: 'English customer service',
            xx179: '보너스 포인트 차감',
            xx226: '体育结算'
        },
    },
    foot: {
        t1: "mago 致力于负责任的赌博，欲了解更多信息，请访问",
        "t2": 'Mago.game은 Jinbei Group이 소유하고 운영합니다. 등록 회사: Altacore N.V. 등록 번호: 151002, 등록 주소: Dr. H. Fergusonweg 1, Gaito, Curacao. support@Mago.game으로 문의해 주세요. 2024년 7월 1일 현재 라이선스 번호 OGL/2023/109/0075로 Curacao Gaming Control Board의 라이선스를 받았습니다. 결제 대행사는 Asia Payment Services Singapore입니다.',
        t3: "支持",
        t4: "合作伙伴",
        t5: "新闻媒体",
    },
    new: {
        "ting": "홀",
        "magoRecommendation": "마고 추천",
        "slots": "슬롯머신",
        "liveCasino": "라이브 카지노",
        "gameShow": "미니 게임",
        "tableGames": "미니 게임",
        "fishing": "어업",
        "bingo": "빙고",
        "newGame": "새로운 게임",
        "liveCasinoTxt": "라이브 카지노",
        "searchGamePlaceholder": "게임 검색",
        "recharge": "충전하다",
        "withdraw": "현금 인출",
        "favorites": "즐겨찾기",
        "recentGames": "최근 게임 기록",
        "vipClub": "VIP 클럽",
        "alliancePlan": "제휴 프로그램",
        "gamesTitle": "게임",
        "sports": "스포츠",
        "gameProvider": "게임 제공업체",   //
        "languageSelection": "언어 선택",
        "timezone": "시간대",//
        "onlineSupport": "온라인 지원",
        "aboutUs": "회사 소개",
        "antiMoneyLaundering": "자금세탁 방지 정책",
        "license": "특허",
        "responsibleGambling": "책임 있는 도박",
        "termsOfService": "서비스 약관",
        "logout": "그만두다",
        "fbLiveCasino": "페이스북 라이브 영상",
        "loadingText": "로드 중",
        "recommendation": "마고 추천",
        "myFavorites": "내 컬렉션",
        "gameHistory": "게임 기록",
        "noData": "아직 데이터가 없습니다",
        "noFavoritesData": "아직 수집 데이터가 없습니다.",
        "noGamesData": "아직 게임 데이터가 없습니다.",
        "gameProviderPlaceholder": "게임 제공업체",
        "exclusiveAlliancePlan": "독점 제휴 프로그램",
        "joinMogo": "Mogo의 제휴 프로그램에 가입하고 브랜드를 추천하여 수익을 얻으세요! 우리의 파트너가 되어 수익의 최대 55%를 받으세요. 추천 플레이어의 수익에서 일정 비율의 커미션을 받게 됩니다. 성공을 함께 나누자",
        "becomePartner": "파트너가 되어보세요",
        "exclusiveVIPExperience": "비교할 수 없는 VIP 경험",
        "unlockExclusiveBenefits": "특별한 혜택을 잠금 해제하고 조건 없이 즉시 인출할 수 있는 보너스를 받으세요.",
        "register": "등록하다",
        "vipRankingSystem": "마고 VIP 랭킹 시스템",
        "bettingAmount": "베팅 금액",
        "vipClubBenefits": "마고 VIP클럽 혜택",
        "yourVIPProgress": "귀하의 VIP 진행 상황",
        "currentLevel": "현재 레벨",
        "currentLevelValue": "V",
        "expectedWeeklyMonthlyBonus": "플레이 볼륨에 따라 주간 및 월간 보너스를 기대할 수 있습니다. 더 많이 플레이할수록 더 많은 보너스를 얻을 수 있습니다.。",
        "exclusiveVIPServiceRepresentative": "귀하의 베팅 요구 사항을 지원하고 충족할 전담 VIP 서비스 담당자를 배정받으세요.",
        "recentGamePerformanceBonus": "불행? Stake는 레벨이 오를 때마다 손실에 따라 추가 자금을 제공합니다.",
        "levelUpBonus": "각 다음 레벨에 도달한 후 보너스를 받으세요. 레벨이 높을수록 업그레이드 보너스가 커집니다.",
        "bespoke": "전담 VIP 서비스 담당자와 협력하여 베팅 요구 사항에 맞게 혜택을 맞춤화하세요.",
        "bronze": "Bronze",
        "silver": "Silver",
        "gold": "Gold",
        "platinum": "Platinum I-III",
        "inpWithPwd": "펀드 비밀번호를 입력해주세요",
        "inpConfirmPwd": "자금 확인 비밀번호를 입력해주세요",
        "pwdMismatch": "두 비밀번호가 일치하지 않습니다.",
        "nameRequired": "이름은 비워둘 수 없습니다.",
        "pwdLabel": "펀드 비밀번호",
        "confirmPwdLabel": "펀드 비밀번호 확인",
        "fillPrompt": "안내에 따라 입력해주세요.",
        "saveContinue": "저장하고 계속하세요",
        "confirmDetails": "세부 사항을 확인하세요",
        "fillDetails": "추가 서비스를 잠금 해제하려면 세부 정보를 입력하고 신원을 확인하세요. 제공된 정보는 비공개이며 안전합니다.",
        "realNameLabel": "실명",
        "birthdayLabel": "당신의 생일",
        "promotionTitle": "프로모션",
        "eventTitle": "US오픈",
        "bonusTitle": "에이스볼 더블 보너스",
        "readMore": "더 읽어보세요",
        "experienceNow": "지금 사용해 보세요",
        "viewAll": "모두 보기",
        "playing": "사람들이 놀고 있어요",
        "cancel": "제거하다",
        "start": "시작",
        "login": "로그인",
        "collect": "모으다",
        "today": "오늘",
        "win": "이기다",
        "draw": "평평한",
        "lose": "부담",
        "setWallet": "지갑을 설정하고 플레이를 시작하세요!",
        "goSetPwd": "설정으로 이동",
        "pointsSystem": "포인트 시스템",
        "Browse": "먹다",
        "Casino": "카지노",
        "Bets": "베팅 전표",
        "Sports": "체육",
        "Chat": "고객 서비스",//
        "firstTelegram": "TELEGRAM miniAPP",
        "miniProgramCasino": "온라인 카지노",
        "followTelegram": "공식 텔레그램 채널을 팔로우하세요",
        "trialBonusChannel": "평가판 보너스 받기",
        "enterNow": "지금 입장하세요",
        "announcement": "발표",
        "magoJoinFB": "FB 라이브 카지노",
        "exclusiveAnchor": "새로운 디자인 스타일",
        "t4": "당신에게 다른 경험을 가져다",
        "playNow": "지금 와서 놀아보세요",
        "aboutUs1": 'Mago娱乐城属于金贝集团所属经营，Mago是亚洲值得信赖的在线赌博网站，自从正式投入服务至今最完整和最新的在线赌博游戏。 我们有一个简单的使命和愿景是提供诚实和信任的服务。',
        "aboutUs2": "除了体育游戏和真人娱乐,我们还与许多主要娱乐公司合作。因此,始终确保提供最优质的娱乐博彩服务和产品。不仅带来了信誉良好且专业的博彩市场。还有一个多样化的游乐场,为每个人带来最好的选择。",
        "aboutUs3": '最热门的投注产品。体育博彩领域，包括足球、篮球、排球、网球比赛,...非常迷人。玩家参与投注，观看世界上最大的赛季的激烈比赛。通过准确的赔率，有吸引力的回扣优惠满足您对投注的热情。',
        "aboutUs4": '与传统的赌场赌桌不同,今天我们也有真人娱乐场。在Mago 注册帐户后，玩家可以与辣妹一起加入真人娱乐场。您将通过直播屏幕与真人一起加入赌桌。不仅增加了吸引力和趣味性，还为玩家创造了更多的真实性和专业性',
        "aboutUs5": 'Mago最吸引人的娱乐产品之一是老虎机游戏。我们提供来自全球主要游戏制造商的数千种老虎机。提供具有不同颜色,内容和奖品的多样化体验。',
        "aboutUs6": '除了真人娱乐,Mago仍然提供具有醒目 3D 图形的赌博游戏。来自传统的棋牌游戏，游戏如龙虎、百家乐、二十一点,...',
        "aboutUs7": '捕鱼是一个非常熟悉的游戏,既具有很高的娱乐性,又有助于赚取额外的钱。来到Mago,捕鱼变成了具有更多独特内容和图形的多样性。',
        "aboutUs8": '拥有库拉索GCB颁发的合法执照。注册于威廉斯塔德,是受国际认可的合法公司因此,在这里参与投注和赌场的玩家不必担心合法性。Mago配备了先进的技术设备,采用专业的安全系统。因此,玩家的所有信息都将是安全的，不会暴露在外部。如果有未经授权的第三方,我们也会及时发现并防止。另外我们始终拥有最专业的客户服务人员。确保 24/7 全天候支持并回答您的所有问题。这种优势也是我们在向国际友人介绍Mago时最有信心的。因此,在需要时,请联系客服人员以获取建议和答案。我们希望您能更好地了解我们。不要忘记。注册一个Mago 帐户,以便能够参与这个令人兴奋的投注游乐场',
        "aboutUs2T": "游戏产品",
        "aboutUs3T": "体育游戏",
        "aboutUs4T": "真人娱乐 ",
        "aboutUs5T": "老虎机游戏 ",
        "aboutUs6T": "棋牌游戏 ",
        "aboutUs7T": "捕鱼游戏 ",
        "aboutUs8T": "Mago娱乐城的优势 ",
        "my": '내 거',
        "active": '활동',
    }
}
