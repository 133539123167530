import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import VueClipboard from 'vue-clipboard2';
import VueRouter from 'vue-router';
import vueResource from 'vue-resource';
import axios from "axios"
Vue.prototype.$axios = axios;
import VueI18n from 'vue-i18n';
import Api from './config/api';
import GameConfig from './config/gameConfig';
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import locale from 'view-design/dist/locale/zh-CN';
import VueMarquee from 'vue-marquee-component';

import EasySlider from "vue-easy-slider";
import VueAnalytics from 'vue-analytics';
import moment from 'moment'
import Calendar from 'vue-mobile-calendar'
import './assets/scss/global.scss'
import vueScrollwatch from "vue-scrollwatch"
Vue.use(vueScrollwatch)
import directive from './util/debounce'
Vue.use(directive)
import "vue-easytable/libs/theme-default/index.css";
import VueEasytable from "vue-easytable";
const EventBus = new Vue();
Vue.prototype.$bus = EventBus;
Vue.use(VueEasytable);

Vue.use(Calendar);

Vue.use(VueClipboard);
Vue.use(VueRouter);
Vue.use(vueResource);

Vue.use(VueI18n);
Vue.use(ViewUI, { locale });
// Vue.use(Carousel3d);
Vue.use(VueMarquee);
Vue.use(EasySlider);

Vue.use(VueAnalytics, {
  id: 'UA-238143558-1',
  router
})

// Vue.prototype.host = "https://bw99.tg86.org";
// Vue.prototype.hostSports = "https://bw99.tg86.org";
Vue.prototype.host = "https://api.mago.game";
Vue.prototype.hostSports = "https://api.mago.game";

// 测试环境本地：http://43.198.38.108:9920
// 测试环境线上：https://www.jbbet.io/jinbei-api
// 7007   正式后台后端端口    8008  正式H5后端端口



Vue.prototype.gameConfig = GameConfig
Vue.prototype.api = Api;
Vue.http.options.credentials = false;
Vue.http.options.emulateJSON = false;
// Vue.http.options.headers = {
//     'Content-Type': 'application/json;charset=UTF-8',
//     // Content-Type: application/json;charset=utf-8
// };

Vue.http.options.headers = {
  // 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
  'Content-Type': 'application/json'
}


Vue.filter('datefomt', function (input, fomtstr) {
  return moment(input).format(fomtstr)
});

// window.onbeforeunload=function(){
//     return '';
// }

let langue = navigator.language?navigator.language:navigator.userLanguage



const i18n = new VueI18n({
  locale: langue,
  messages: {
      'zh-CN': require('./assets/lang/cn.js'),
      'en-US': require('./assets/lang/en.js'),
      'zh-HK': require('./assets/lang/hk.js'),
      'ca-CB': require('./assets/lang/ca.js'),  // 柬埔寨
      'vi-VN': require('./assets/lang/vi.js'),
      'ja-JP': require('./assets/lang/jp.js'),
      'th-TH': require('./assets/lang/th.js'),
      'ko-KR': require('./assets/lang/co.js'),
      'ma-MA': require('./assets/lang/ma.js'),
      'in-IN': require('./assets/lang/in.js')
  },
  silentTranslationWarn: true
});


Vue.http.interceptors.push((request, next) => {
  //登录成功后将后台返回的TOKEN在本地存下来,每次请求从sessionStorage中拿到存储的TOKEN值
  request.headers.set('Authorization', localStorage.getItem('TOKEN'));
  // request.headers.set('Content-Type' , 'application/json;');


let lang = localStorage.getItem('LANGUAGE') == null? langue:localStorage.getItem('LANGUAGE');



if (lang.includes('en')) lang = 'en-US'
else if (lang.includes('zh')) lang = 'zh-CN'
else if (lang.includes('vi')) lang = 'vi-VN'
else if (lang.includes('th')) lang = 'th-TH'
else if (lang.includes('ja')) lang = 'ja-JP'
else if (lang.includes('ko')) lang = 'ko-KR'
else if (lang.includes('in')) lang = 'in-IN'
  localStorage.setItem('LANGUAGE', lang)
  i18n.locale = lang
request.headers.set('lang', lang);

  next((response) => {
      //登录极验证时需获取后台返回的TOKEN值
      // let xAuthToken = response.headers.get('x-auth-token');
      // if (xAuthToken != null && xAuthToken != '') {
      //     localStorage.setItem('TOKEN', xAuthToken);
      // }

      if (response.data.code == '1402' || response.data.code == '3000') {
          return false;
      } else if (response.data.code == '401') {
        localStorage.setItem('TOKEN', null)
        router.push('/login');
        return false;
      }

      return response;
  });
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
