module.exports = {
    common: {
        lang: "中文",
        login1: '已有账号？',
        register: '注册',
        logout: '退出登录',
        tip: '温馨提示',
        logintip: '请先登录',
        set: '设置',
        amount: "余额",
        close: "关闭",
        ok: "确定",
        capitalPass: '资金密码',
        capitalPassinput: '请输入资金交易密码',
        realNameInput: '请输入银行卡姓名',
        huioneNameInput: '请输入汇旺姓名',
        huioneName: '汇旺账号名',
        nicknameInput: '请输入昵称',
        usertip: '付款成功',
        tibifirst: "详情",
        message: "信息",
        service: '7/24小时',
        ads: '优惠活动',
        aboutus: '关于我们',
        pleaseselectService: "请选择存款方式",
        pleaseselectCurrency: "请选择货币种类",
        currencyType: '货币',
        pleaseselectService1: "请选择提款方式",
        pleaseselectService2: "请选择转账方式",
        tabbar1: '浏览',  //
        tabbar2: '娱乐城',  //
        tabbar3: '投注单',  //
        tabbar4: '体育',  //
        tabbar5: '客服',  //
        date: '请选择时间段',
        date1: '总注册人数',
        date2: '总输赢',
        date3: '总投注量',
        date4: '会员ID',
        date5: '会员投注量',
        date6: '会员输赢',
        date7: '总有效投注',
        date8: '会员有效投注',
        date9: '商务合作',
        date10: '收款ID :',
        exchange: '积分兑换',

        notice23: '温馨提示：目前玩家只能向代理账户转账, 请知悉 !!',
        categorySport: '体育',
        categoryLive: '真人',
        categorySlot: '老虎机',
        categoryFish: '捕鱼',
        categoryBingo: '彩票',
        categoryPoke: '棋牌',
        gender: '性別',
        birthday: '生日',
        nickname: '昵称',
        male: '男',
        female: '女',
        name: '姓名',
        usdt: 'USDT',
        usd: '美元',
        exchangeAll: '全部',
        gOut: '转出',
        goIn: '转入',
        meamount: '余额',
        mebonus: '积分',
        agSport: '游戏',
        sbSport: '支付额',
        ibSport: 'BTI 体育',
        dgLive: 'DG 真人',
        agLive: 'AG 真人',
        gbLive: 'PP 真人',
        E0Live: 'EVO 真人',
        wcLive: 'WM 真人',
        oneKey: '一键归账'

    },
    uc: {
        login: {
            login: '登录',
            usertip: '请输入手机号',
            pwdtip: '请输入密码',
            validatecodeload: '正在加载验证码',
            validatemsg: '请先完成验证',
            forget: '忘记密码?',
            loginvalidate: '请输入账号',
            pwdvalidate1: '请输入密码',
            pwdvalidate2: '密码长度不能少于6位',
            success: '登录成功',
            goregister: "注册",
            getlostpwd: "忘记密码",
            nouser: "用户不存在",
            normaluser: '对方账号不支持转账',
            slogan: '亚洲最信赖博彩平台之一',
            toSetting: '去设置',
            settinghuione: '请先设置汇旺用户名',
            settingbank: '请先设置银行卡用户名'
        },
        regist: {
            smscode: '短信验证码',
            sendcode: '发送验证码',
            resendcode: '重新发送(',
            pwd: '登录密码',
            repwd: '确认密码',
            agreement: '我已阅读并同意',
            userprotocol: '用户协议',
            regist: '注册',
            success: '注册成功',
            teltip: '请输入手机号',
            telerr: '手机号码格式不正确,请重新输入',
            emailtip: '请输入邮箱',
            emailerr: '密码不正确,请重新输入',
            confirmpwdtip: '请输入确认密码',
            confirmpwderr: '两次密码输入不一致!',
            telregist: '手机注册',
            emailregist: '邮箱注册',
            smscodetip: '请输入短信验证码',
            pwdtip: '请输入登录密码',
            pwdmsg: '密码长度不能少于6位',
            telno: '手机号码',
            email: '邮箱地址',
            agreementtip: '请点击同意',
            modaltitle: '请校验',
            promotion: '代理ID(非必填)',
            china: '简体中文',
            singapore: 'English',
            thailand: 'แบบไทย',
            vietnam: 'Tiếng Việt',
            india: 'Indonesia',
            japan: 'にほんご ',
            korea: '한국어',
            hk: '繁體中文',
            malaysia: 'Melayu',
            taiwan: '繁體中文',
            cambodia: 'កម្ពុជា។',
            uk: 'English'
        },
        forget: {
            newpwd: '请输入新密码',
            confirmpwd: '请再次确认密码',
            confirmpwd11: '收款用户ID',
            save: '提交',
            pwdvalidate1: '请输入确认密码',
            pwdvalidate2: '两次密码输入不一致!',
            resettelpwd: '登录密码',
            resetemailpwd: '资金密码',
            newpwdtip: '请输入新密码',
            pwdvalidate3: '密码长度不能少于6位',
            smscode: '请输入验证码',
            teltip: '请输入手机号',
            smscodetip: '请输入短信验证码',
            email: '邮箱',
            emailcode: '邮箱验证码',
            emailtip: '请输入邮箱',
            emailcodetip: '请输入资金密码',
            resetpwdsuccess: '重置密码成功',
            addresstip: '请输入提币地址',
            addresstip21: '请输入收款用戶ID',
            addresstip221: '请输入账号',
            addresstip222: '请输入收款用戶银行卡号',
            emailcodetip1: '请设置资金密码',
            emailcodetip11: '请设置提币地址',
            walletaddress: '钱包地址',
            huioneaddress: '汇旺账号',
            walletaddress1: '收款地址',
            walletaddress2: '实际到账金额',
            walletaddress21: '金额必须大于0',
            wallet: '钱包',
            userID: '账号ID',
            upload: '+上传凭证',
            addresstip31: '付款者姓名',
            addresstip41: '付款者账号',
            addresstip311: '姓名',
            addresstip321: '请输入姓名',
            personal: '个人信息'
        },
        finance: {
            center: '个人中心',
            personalassets: '网络',
            swapassets: '地址',
            swapassets1: '账号',
            recordetail: '交易明细',
            tradetail: '余额',
            invitingmining: '金额',
            charge: '存款',
            pickup: '取款',
            copy: '复制',
            choose: '选取',
            cards: '银行卡',
            copysuccess: '复制成功',
            copyfailed: '复制失败',
            notice: "网络服务费/手续费由会员支付",
            notice1: "每位会员都有独立的USTD存款地址, 转账成功后系统会根据转账金额自动到账,  如遇长时间未到账请及时联系主页客服进行咨询",
            notice11: '转账成功后，系统会自动到账，如遇长时间未到账请及时联系主页客服进行咨询',
            transfee: "手续费",
            emailcodetip: '资金密码',
            gamedetail: '注单记录',
            gametype: '游戏类型',
            game0: '瓦力游戏',
            game81: '真人视讯游戏',
            game82: '棋牌游戏',
            game83: '电子游戏',
            game84: '体育游戏',
            game85: '老虎机游戏',
            game86: '彩票游戏',
            game87: '捕鱼游戏',
            game88: '扑克游戏',
            game89: '迷你游戏',
            game90: '其他游戏',
            game91: '视讯色碟',
            game92: '百家乐',
            game93: '龙虎',
            game94: '骰宝',
            game95: '超牛',
            game96: '牛牛',
            game97: '美式轮盘',
            game98: '炸金花',
            game99: '色碟',
            game100: '21点',
            game101: '超牛',
            game102: '安达巴哈',
            game103: '番摊',
            game104: '三公',
            fbbet: '体育下注',
            bet: '投注金额',
            validBet: '投注',
            profit: '盈亏',
            addUaddress: '卡片管理',
            addaddress: '添加提款信息',
            trans: '转账',
            agentdata: '代理数据',
            agentpage: '商务合作',
            timezone: '时区',
            agentapply: '立刻申请',
            agentnotice: '请先申请代理',
            datapage: '代理数据',
            promotion: '代理推广',
            promotion1: '代理推广码',
            promotion2: '保存二维码',
            promotion3: '推广链接',
            notice12: "提款信息设置成功以后只能通过联系客服修改",
            notice123: "请输入付款方信息",
            notice1234: "操作成功，会及时处理，请稍等",
            notice12345: "请输入有效金额",
            xx1: '充值订单',
            xx2: '提现订单',
            xx3: '状态',
            xx4: '审核中',
            xx5: '成功',
            xx6: '失败',
            xx7: '充值人姓名',
            xx8: '充值账号',
            xx9: '提现人姓名',
            xx10: '提现账号',
            xx11: '备注',
            xx12: '账单创建时间',
            xx13: '用户编号',
            xx14: '没有更多数据',
            xx15: '会员转账',
            xx17: '转账提现',
            xx18: '转账充值',
            xx19: '线上充值',
            xx20: '手动充值',
            xx21: 'U盾提现',
            xx22: '手动提现',
            xx23: '游戏上分',
            xx24: '游戏下分',
            xx25: '彩金',
            xx26: '补分',
            xx27: '扣分',
            xx28: '注册红包',
            xx29: '订单号',
            xx30: '交易时间',
            xx31: '交易后余额',
            xx32: '游戏返水',
            xx33: '尊敬的用户',
            xx34: '下线会员',
            xx35: '本月新注册会员',
            xx36: '本月活跃会员',
            xx37: '本月净输赢',
            xx38: '返佣比例',
            xx39: '本月佣金',
            xx40: '推广码',
            xx41: '专用域名',
            xx42: '下线会员列表',
            xx43: '佣金报表',
            xx44: '搜索会员账号',
            xx45: '会员账号',
            xx46: '最后登录时间',
            xx47: '注册时间',
            xx48: '本期佣金',
            xx49: '总输赢',
            xx50: '返点总计',
            xx51: '彩金',
            xx52: '金流折损费',
            xx53: '金流手续费',
            xx54: 'VIP额外佣金',
            xx55: 'VIP彩金',
            xx56: '佣金比例',
            xx57: '账单明细',
            xx58: '本期佣金',
            xx59: '平台费用',
            xx60: '收款账号',
            xx61: 'cam充值',
            xx62: 'cam提现',
            xx63: '支付中',
            xx64: '支付成功',
            xx65: '支付失败',
            xx66: '账单完成时间',
            xx67: '充值方式',
            xx68: '币种',
            xx69: '提现方式',
            platform: '游戏平台',
            bitStatus: '注单状态',
            xx70: '有效注单',
            xx71: '赛事进行中',
            xx72: '无效注单',
            xx73: '开始时间',
            xx74: '结束时间',
            xx75: '注单号',
            xx76: '单号',
            xx77: '玩家ID',
            xx78: '桌号',
            xx79: '游戏结果',
            xx80: '玩家单号',
            xx81: '选择语言',
            xx82: '欢迎来到客服中心',
            xx83: 'HI, 尊敬的客户',
            xx84: '高棉语客服',
            xx85: '越南语客服',
            xx86: '中国语客服',
            xx87: '英语客服',
            xx88: '银行卡姓名',
            xx89: '银行卡账号',
            xx91: '银行名称',
            xx92: '7x24 小时专线服务, 贴心至上',
            xx93: '添加银行卡',
            xx94: '添加提现地址',
            xx95: '确定返回大厅吗？游戏中的钱如果没有正确返回，请在我的页面使用一键归账功能',
            xx96: '联赛名称',
            xx97: '主队',
            xx98: '客队',
            xx99: '比分',
            xx100: '所有信息都是必填',
            xx101: '关于Jbbet',
            xx102: 'Jbbet是许多人喜欢的博彩娱乐目的地。这是当今顶级信誉良好和优质博彩网站中备受赞赏的游乐场。Jbbet不仅在东南亚享有盛誉,而且在整个亚洲市场博彩市场上也占有重要地位。',
            xx103: 'Jbbet的诞生',
            xx104: '尽管Jbbet最近很受欢迎,但它已经在亚洲市场经营多年。Jbbet成立于2005年,在柬埔寨正式运营。感谢PAGCOR在颁发运营许可证方面的合作。这家博彩公司有许多深受玩家喜爱的博彩类游戏.',
            xx105: 'Jbbet的产品和服务',
            xx106: '除了体育游戏和真人娱乐,我们还与许多主要娱乐公司合作。因此,始终确保提供最优质的娱乐博彩服务和产品。不仅带来了信誉良好且专业的博彩市场。还有一个多样化的游乐场,为每个人带来最好的选择。',
            xx107: '体育游戏',
            xx108: '最热门的投注产品。体育博彩领域，包括足球、篮球、排球、网球比赛,...非常迷人。玩家参与投注，观看世界上最大的赛季的激烈比赛。通过准确的赔率，有吸引力的回扣优惠满足您对投注的热情。',
            xx109: '真人娱乐',
            xx110: '与传统的赌场赌桌不同,今天我们也有真人娱乐场。在Jbbet 注册帐户后，玩家可以与辣妹一起加入真人娱乐场。您将通过直播屏幕与真人一起加入赌桌。不仅增加了吸引力和趣味性，还为玩家创造了更多的真实性和专业性',
            xx111: '老虎机游戏',
            xx112: 'Jbbet最吸引人的娱乐产品之一是老虎机游戏。我们提供来自全球主要游戏制造商的数百种老虎机。提供具有不同颜色,内容和奖品的多样化体验。',
            xx113: '棋牌游戏',
            xx114: '除了真人娱乐,Jbbet仍然提供具有醒目 3D 图形的赌博游戏。来自传统的棋牌游戏，游戏如龙虎、百家乐、二十一点,... ',
            xx115: '捕鱼游戏',
            xx116: '捕鱼是一个非常熟悉的游戏,既具有很高的娱乐性,又有助于赚取额外的钱。来到Jbbet,捕鱼变成了具有更多独特内容和图形的多样性。',
            xx117: 'Jbbet的优势',
            xx118: '拥有欧洲马耳他MGA颁发的合法执照。注册于英属维尔京群岛,是受国际认可的合法公司因此,在这里参与投注和赌场的玩家不必担心合法性。Jbbet配备了先进的技术设备,采用专业的安全系统。因此,玩家的所有信息都将是安全的，不会暴露在外部。如果有未经授权的第三方,我们也会及时发现并防止。另外我们始终拥有最专业的客户服务人员。确保 24/7 全天候支持并回答您的所有问题。这种优势也是我们在向国际友人介绍Jbbet时最有信心的。因此,在需要时,请联系客服人员以获取建议和答案。',
            xx119: '我们希望您能更好地了解我们。不要忘记。注册一个Jbbet 帐户,以便能够参与这个令人兴奋的投注游乐场',
            xx120: '不支持此币种,请去钱包页面兑汇或者切换货币',
            xx121: '付款人姓名',
            xx122: 'USDT地址(TRC20)',
            xx123: '地址管理',
            xx124: '全部',
            xx125: '充值',
            xx126: '提现',
            xx127: '取消',
            xx128: '申请中',
            xx129: '已确认',
            xx130: '已驳回',
            xx131: 'FB体育',
            xx1311: 'FB视讯',
            xx132: '游戏记录',
            xx133: '彩票',
            xx134: '已结',
            xx135: '未结',
            xx136: '取消',
            xx137: '昨日',
            xx138: '今日',
            xx139: '近7日',
            xx140: '近30日',
            xx141: '单注',
            xx142: '串场',
            xx143: '冠军',
            xx144: '自选日期',
            xx145: 'Mago客服',
            xx146: '隐藏',
            xx147: '测试充值',
            xx148: '游戏下注',
            xx149: 'CMD体育',
            xx150: 'Saba体育',
            xx151: '乐游',
            xx152: '比赛日期',
            xx153: '积分变动',
            xx154: 'USDT 兑入',
            xx155: '泰国语客服',
            xx156: '印尼语客服',
            xx157: 'JDB 老虎机',
            xx158: 'PG 老虎机',
            xx159: 'PP 老虎机',
            xx160: 'NT 老虎机',
            xx161: 'JDB 捕鱼',
            xx162: 'HI, 欢迎您的到来',
            xx163: '没有账户？',
            xx164: '我的货币',
            xx165: '兑换',
            xx166: '注册红包',
            xx167: 'CMD系统调账',
            xx168: '游戏买断',
            xx169: '游戏买入',
            xx170: '累计奖金',
            xx171: '奖金',
            xx172: '取消投注',
            xx173: '回滚投注',
            xx174: '游戏结算',
            xx175: '游戏下注',
            xx176: '提现失败退还',
            xx177: '会员转账',
            xx178: '扣分',
            xx179: '彩金扣分',
            xx180: '补分',
            xx181: '彩金',
            xx182: '赠送',
            xx183: '汇旺',
            xx184: 'uDUN',
            xx185: '提现',
            xx186: '充值',
            xx187: '汇旺',
            xx188: '提现',
            xx189: '请选择银行',
            xx190: '从：',
            xx191: '至：',
            xx192: '积分与 ',
            xx193: '赢',
            xx194: '赢',
            xx195: '输',
            xx196: '输',
            xx197: '平',
            xx198: '未结算',
            xx199: '兑换金额不能低于1',
            xx200: '姓名',
            xx201: '输入密码',
            xx202: '提现密码',
            xx203: '返回',
            xx204: '保存',
            xx205: '暂无记录',
            xx206: '积分兑换',
            xx207: '欧洲盘',
            xx208: '香港盘',
            xx209: '马来盘',
            xx210: '印尼盘',
            xx211: '可赢',
            xx212: '已创建',
            xx213: '确认中',
            xx214: '已拒绝',
            xx215: '已取消',
            xx216: '已确认',
            xx217: '已结算',
            xx218: '我的积分',
            xx219: ' 兑换比例：',
            xx220: ' 积分',
            xx221: ' 积分获得',
            xx222: ' vip等级',
            xx223: '彩金扣分',
            xx224: 'jili押金退回',
            xx225: 'jili押金',
            xx226: '体育结算',
            xx227: '赠送'
        },
    },
    foot: {
        t1: "mago 致力于负责任的赌博，欲了解更多信息，请访问",
        "t2": 'Mago娱乐城 由金贝集团所属和经营，注册公司：Altacore N.V. 注册编号：151002，注册地址： Dr. H. Fergusonweg 1, Gaito, 库拉索。请通过 support@Mago.game 与我们联系。自 2024 年7月1日起获得库拉索岛游戏控制委员会许可，许可证号为 OGL/2023/109/0075 支付代理公司是：新加坡亚洲支付服务商。',
        t3: "支持",
        t4: "合作伙伴",
        t5: "新闻媒体",
    },
    new: {
        "ting": "娱乐城",
        "magoRecommendation": "热门游戏",
        "slots": "电子游戏",
        "liveCasino": "真人娱乐场",
        "gameShow": "迷你游戏",
        "tableGames": "桌面游戏",
        "fishing": "捕鱼游戏",
        "bingo": "宾果游戏",
        "newGame": "新游戏",
        "liveCasinoTxt": "真人娱乐场",
        "searchGamePlaceholder": "搜索游戏",
        "recharge": "充值",
        "withdraw": "提现",
        "favorites": "收藏夹",
        "recentGames": "近期游戏记录",
        "vipClub": "VIP俱乐部",
        "alliancePlan": "联盟计划",
        "gamesTitle": "游戏",
        "sports": "体育运动",
        "gameProvider": "游戏提供商",   //
        "languageSelection": "语言选择",
        "timezone": "时区",//
        "onlineSupport": "在线支持",
        "aboutUs": "关于我们",
        "antiMoneyLaundering": "反洗钱政策",
        "license": "许可证",
        "responsibleGambling": "负责任博彩",
        "termsOfService": "服务条款",
        "logout": "退出",
        "fbLiveCasino": "FB真人视讯",
        "loadingText": "加载中",
        "recommendation": "mago推荐",
        "myFavorites": "我的收藏",
        "gameHistory": "游戏记录",
        "noData": "暂无数据",
        "noFavoritesData": "暂无收藏数据",
        "noGamesData": "暂无游戏数据",
        "gameProviderPlaceholder": "游戏提供商",
        "exclusiveAlliancePlan": "独家联盟计划",
        "joinMogo": "加入mogo的联盟计划，以推荐我们的品牌赚钱！成为我们的合作伙伴，获取最高55%的收益。将您从推荐的玩家收益中获得一定比例的佣金。让我们一起分享成功吧",
        "becomePartner": "成为合作伙伴",
        "exclusiveVIPExperience": "无与伦比的 VIP 体验",
        "unlockExclusiveBenefits": "解锁专属福利并获得无需任何附加条件即可立即提取的奖金。",
        "register": "注册",
        "vipRankingSystem": "Mago VIP 排名系统",
        "bettingAmount": "投注金额",
        "vipClubBenefits": "Mago VIP 俱乐部福利",
        "yourVIPProgress": "您的VIP进度",
        "currentLevel": "当前等级",
        "currentLevelValue": "V",
        "expectedWeeklyMonthlyBonus": "您可以预期每周和每月根据您的游戏量获得奖金。玩得越多，获得的奖金就越多。",
        "exclusiveVIPServiceRepresentative": "被分配一名将支持并满足您的投注需求的专属 VIP 服务代表。",
        "recentGamePerformanceBonus": "运气不佳？Stake 会在您每次升级时根据您的损失提供额外资金。",
        "levelUpBonus": "在每达到下个级别后获取奖金。所达级别越高，升级奖金就越大。",
        "bespoke": "与您的专属 VIP 服务代表合作，根据您的投注需求定制福利。",
        "bronze": "Bronze",
        "silver": "Silver",
        "gold": "Gold",
        "platinum": "Platinum I-III",
        "inpWithPwd": "请输入资金密码",
        "inpConfirmPwd": "输入确认资金密码",
        "pwdMismatch": "两次密码不一致",
        "nameRequired": "姓名不可为空",
        "pwdLabel": "资金密码",
        "confirmPwdLabel": "确认资金密码",
        "fillPrompt": "请按照提示填写",
        "saveContinue": "保存然后继续",
        "confirmDetails": "确认您的详细信息",// kaish
        "fillDetails": "请填写您的详细信息并确认您的身份以解锁附加服务。所提供的信息都是私密且安全的。",
        "realNameLabel": "真实姓名",
        "birthdayLabel": "您的生日",
        "promotionTitle": "促销活动",
        "eventTitle": "美国网球公开赛",
        "bonusTitle": "Ace球双倍奖金",
        "readMore": "阅读更多",
        "experienceNow": "马上体验",
        "viewAll": "查看全部",
        "playing": "人在玩",
        "cancel": "取消",
        "start": "开始",
        "login": "登陆",
        "collect": "收藏",
        "today": "今日",
        "win": "胜",
        "draw": "平",
        "lose": "负",
        "setWallet": "让我们设置您的钱包，然后开始游戏吧！",
        "goSetPwd": "前往设置",
        "pointsSystem": "积分系统",
        "Browse": "浏览",
        "Casino": "娱乐场",
        "Bets": "投注单",
        "Sports": "体育",
        "Chat": "客服",
        "firstTelegram": "Telegram小程序",//
        "miniProgramCasino": "在线娱乐场",//
        "followTelegram": "关注Telegram官方频道",//
        "trialBonusChannel": "领取体验金",//
        "enterNow": "立即进入",
        "announcement": "公告",
        "magoJoinFB": "FB真人视讯",//
        "exclusiveAnchor": "全新设计风格",//
        "t4": "给您带来不一样的体验",//
        "playNow": "马上来玩",
        "aboutUs1": 'Mago娱乐城属于金贝集团所属经营，Mago是亚洲值得信赖的在线赌博网站，自从正式投入服务至今最完整和最新的在线赌博游戏。 我们有一个简单的使命和愿景是提供诚实和信任的服务。',
        "aboutUs2": "除了体育游戏和真人娱乐,我们还与许多主要娱乐公司合作。因此,始终确保提供最优质的娱乐博彩服务和产品。不仅带来了信誉良好且专业的博彩市场。还有一个多样化的游乐场,为每个人带来最好的选择。",
        "aboutUs3": '最热门的投注产品。体育博彩领域，包括足球、篮球、排球、网球比赛,...非常迷人。玩家参与投注，观看世界上最大的赛季的激烈比赛。通过准确的赔率，有吸引力的回扣优惠满足您对投注的热情。',
        "aboutUs4": '与传统的赌场赌桌不同,今天我们也有真人娱乐场。在Mago 注册帐户后，玩家可以与辣妹一起加入真人娱乐场。您将通过直播屏幕与真人一起加入赌桌。不仅增加了吸引力和趣味性，还为玩家创造了更多的真实性和专业性',
        "aboutUs5": 'Mago最吸引人的娱乐产品之一是老虎机游戏。我们提供来自全球主要游戏制造商的数千种老虎机。提供具有不同颜色,内容和奖品的多样化体验。',
        "aboutUs6": '除了真人娱乐,Mago仍然提供具有醒目 3D 图形的赌博游戏。来自传统的棋牌游戏，游戏如龙虎、百家乐、二十一点,...',
        "aboutUs7": '捕鱼是一个非常熟悉的游戏,既具有很高的娱乐性,又有助于赚取额外的钱。来到Mago,捕鱼变成了具有更多独特内容和图形的多样性。',
        "aboutUs8": '拥有库拉索GCB颁发的合法执照。注册于威廉斯塔德,是受国际认可的合法公司因此,在这里参与投注和赌场的玩家不必担心合法性。Mago配备了先进的技术设备,采用专业的安全系统。因此,玩家的所有信息都将是安全的，不会暴露在外部。如果有未经授权的第三方,我们也会及时发现并防止。另外我们始终拥有最专业的客户服务人员。确保 24/7 全天候支持并回答您的所有问题。这种优势也是我们在向国际友人介绍Mago时最有信心的。因此,在需要时,请联系客服人员以获取建议和答案。我们希望您能更好地了解我们。不要忘记。注册一个Mago 帐户,以便能够参与这个令人兴奋的投注游乐场',
        "aboutUs2T": "游戏产品",
        "aboutUs3T": "体育游戏",
        "aboutUs4T": "真人娱乐 ",
        "aboutUs5T": "老虎机游戏 ",
        "aboutUs6T": "棋牌游戏 ",
        "aboutUs7T": "捕鱼游戏 ",
        "aboutUs8T": "Mago娱乐城的优势 ",
        "my": '我的',
        "active": '活动',
    },
}
