module.exports = {
    common: {
        lang: '日本語',
        login1: 'すでに登録済みの方はログインしてください',
        register: '新規登録',
        logout: 'ログアウト',
        tip: 'ヒント',
        logintip: '最初にログイン',
        set: '設定',
        amount: "残高",
        close: "やめる",
        ok: "もちろん",
        capitalPass: '個人情報',
        capitalPassinput: '引き出しパスワードを設定してください',
        realNameInput: '銀行カード名',
        huioneNameInput: 'HUIONE名前',
        huioneName: 'HUIONEアカウント',
        nicknameInput: 'ニックネーム',
        usertip: '入金を完了してください。',
        tibifirst: "詳細",
        message: "情報",
        service: 'スポーツ チャット',
        ads: 'アクティビティ',
        aboutus: '本社に関して',
        pleaseselectService: "オプション",
        pleaseselectCurrency: "私の通貨",
        currencyType: '通貨を選択してください',
        pleaseselectService1: "出金方法",
        pleaseselectService2: "転送方法",
        tabbar1: 'ホーム',
        tabbar2: 'プロモ',
        tabbar3: '賭けをする',
        tabbar4: '私の',
        date: '期間を選択してください',
        date1: 'ダウンラインメンバー',
        date2: '総勝敗',
        date3: '合計ベット額',
        date4: 'アカウント',
        date5: 'ベット額',
        date6: 'ウイニング',
        date7: '合計有効なベット',
        date8: '有効なベット',
        date9: 'プロキシア',
        date10: 'メンバーアカウント :',
        exchange: '交換',

        notice23: '温馨提示：目前玩家只能向代理账户转账, 请知悉 !!',
        categorySport: 'スポーツ',
        categoryLive: 'カジノ',
        categorySlot: 'スロット',
        categoryFish: '釣り',
        categoryBingo: 'ロト',
        categoryPoke: 'ポーカー',
        gender: '性別',
        birthday: 'お誕生日',
        nickname: 'ニックネーム',
        male: '男',
        female: '女',
        name: '名前',
        usdt: 'USDT',
        usd: 'ドル',
        exchangeAll: '全て',
        gOut: '転出',
        goIn: '転入',
        meamount: '残高',
        mebonus: 'ボーナス',
        agSport: 'AG 体育',
        sbSport: '沙巴 体育',
        ibSport: 'BTI 体育',
        dgLive: 'DG 真人',
        agLive: 'AG 真人',
        gbLive: 'BBIN 真人',
        E0Live: 'EVO 真人',
        wcLive: 'WM 真人',
        oneKey: '一键归账'

    },
    uc: {
        login: {
            login: 'ログイン',
            usertip: '携帯番号',
            pwdtip: 'パスワード',
            validatecodeload: '検証コード',
            validatemsg: 'まず認証を完了してください。',
            forget: 'パスワードを忘れた',
            loginvalidate: 'アカウント',
            pwdvalidate1: 'パスワード',
            pwdvalidate2: 'パスワードの長さは 6 文字未満にすることはできません',
            success: 'ログイン成功',
            goregister: "新規登録",
            getlostpwd: "パスワードを忘れた",
            nouser: "ユーザーは存在しません",
            normaluser: '相手のアカウントは送金に対応していません',
            slogan: '亚洲最信赖博彩平台之一',
            toSetting: '設定に移動',
            settinghuione: 'HUIONEユーザー名',
            settingbank: '銀行カードのユーザー名',
        },
        regist: {
            smscode: 'ログイン',
            sendcode: '検証コード',
            resendcode: '再送信(',
            pwd: 'パスワード',
            repwd: 'パスワードを認証する',
            agreement: '私は、確認の上、同意します。',
            userprotocol: 'アカウント開設規約',
            regist: '新規登録',
            teltip: '携帯番号',
            telerr: '正確な携帯番号を入力してください',
            emailtip: '郵便',
            emailerr: 'アカウントのパスワードが間違っています。再入力してください',
            confirmpwdtip: 'パスワード（確認）',
            confirmpwderr: 'エラー!',
            telregist: '携帯電話番号の登録',
            emailregist: 'メール登録',
            smscodetip: '検証コード',
            pwdtip: 'パスワード',
            pwdmsg: 'パスワードの長さは 6 文字未満にすることはできません',
            telno: '携帯番号',
            email: '電子メールアドレス',
            agreementtip: '確認してください',
            modaltitle: '確認してください',
            promotion: 'エージェントID（非必填）',
            china: '简体中文',
            singapore: 'English',
            thailand: 'แบบไทย',
            vietnam: 'Tiếng Việt',
            india: 'Indonesia',
            japan: 'にほんご ',
            korea: '한국어',
            hk: '繁體中文',
            malaysia: 'Melayu',
            taiwan: '繁體中文',
            cambodia: 'កម្ពុជា។',
            uk: 'English'
        },
        forget: {
            newpwd: '新しいパスワード',
            confirmpwd: 'パスワード（確認）',
            confirmpwd11: 'メンバーアカウント',
            save: '提出する',
            pwdvalidate1: 'パスワード',
            pwdvalidate2: 'トのパスワードが間違っています!',
            resettelpwd: 'パスワードを変更する',
            resetemailpwd: '引き出しパスワードの変更する',
            newpwdtip: '新しいパスワード',
            pwdvalidate3: 'パスワードの長さは 6 文字未満にすることはできません',
            smscode: '検証コード',
            teltip: '携帯番号',
            smscodetip: '検証コード',
            email: '郵便',
            emailcode: '検証コード',
            emailtip: '郵便',
            emailcodetip: '引き出しパスワードのする',
            resetpwdsuccess: 'アップデートが完了しました',
            addresstip: '引き出し住所を追加する',
            addresstip21: 'メンバーアカウント',
            addresstip221: 'アカウント',
            addresstip222: '銀行口座',
            emailcodetip1: '引き出しパスワードを設定してください',
            emailcodetip11: '引き出し住所を追加する',
            walletaddress: 'アドレス管理',
            huioneaddress: 'HUIONEアカウント',
            walletaddress1: 'アドレス管理',
            walletaddress2: '実際に受け取った金額',
            walletaddress21: '金額は 0 より大きくなければなりません',
            wallet: 'ウォレッ',
            userID: 'メンバーアカウント',
            upload: 'クリックして認証情報をアップロードします',
            addresstip31: 'HUIONE名前',
            addresstip41: 'HUIONEアカウント',
            addresstip311: '名前',
            addresstip321: '名前',
            personal: '個人情報'
        },
        finance: {
            center: '個人情報',
            personalassets: '入出金ネットワーク',
            swapassets: 'アドレス管理',
            swapassets1: 'アカウント',
            billdetail: '取引明細',
            recordetail: '取引明細',
            tradetail: '残高',
            invitingmining: '金額',
            charge: '入金',
            pickup: '引き出し',
            copy: 'コピー',
            choose: '選択する',
            cards: 'カード管理',
            copysuccess: '正常にコピーされました',
            copyfailed: 'コピーに失敗しました',
            notice: "网络服务费/手续费由会员支付",
            notice1: "每位会员都有独立的USTD存款地址, 转账成功后系统会根据转账金额自动到账,  如遇长时间未到账请及时联系主页客服进行咨询",
            notice11: 'アカウントが長期間受信されない場合は、時間内にカスタマーサービスにご連絡ください',
            transfee: "手数料",
            emailcodetip: '引き出しパスワードを',
            gamedetail: 'ゲーム',
            gametype: 'ゲームの種類',
            game0: 'WL',
            game81: 'ライブビデオゲーム',
            game82: 'ポーカー',
            game83: '電子ゲーム',
            game84: 'スポーツゲーム',
            game85: 'スロット',
            game86: 'ロト',
            game87: '捕鱼游戏',
            game88: '扑克游戏',
            game89: '迷你游戏',
            game90: '其他游戏',
            game91: '视讯色碟',
            game92: '视讯保险百家乐',
            game100: 'WLスポーツ',
            bet: 'ベット情報',
            validBet: '有効なベット',
            profit: 'ウイニング',
            addUaddress: '銀行カード',
            addaddress: '引き出し住所を追加する',
            trans: '移行',
            agentdata: 'コミッションレポート',
            agentpage: '業務提携',
            timezone: 'タイムゾーン設定',
            agentapply: 'すぐに適用する',
            agentnotice: 'まずは代理店にお申込みください',
            datapage: 'コミッションレポート',
            promotion: 'プロモーションの効果',
            promotion1: 'QRコードを宣伝する',
            promotion2: '写真を保存する',
            promotion3: 'プロモーションリンク',
            notice12: "コイン引き出しアドレスが正常に設定された後は、カスタマーサービスに連絡することによってのみ変更できます",
            notice123: "会員アカウントへの振替",
            notice1234: "操作は成功し、時間内に処理されます。お待ちください",
            notice12345: "有効なベット",
            xx1: '入金注文番号',
            xx2: '出金注文番号',
            xx3: '状態',
            xx4: '検討中',
            xx5: '成功',
            xx6: '失敗',
            xx7: '名前',
            xx8: 'アカウント',
            xx9: '名前',
            xx10: 'アカウント',
            xx11: '備考',
            xx12: '時間',
            xx13: 'メンバーアカウント',
            xx14: 'まだありません',
            xx15: '会員振替',
            xx17: '現金の送金と引き出し',
            xx18: '転送とリチャージ',
            xx19: 'オンラインリチャージ',
            xx20: 'オンラインリチャージ',
            xx21: 'U盾の引き出し',
            xx22: 'オンライン引き出し',
            xx23: '入金',
            xx24: '引き出し',
            xx25: 'ボーナス',
            xx26: 'ポイントを補う',
            xx27: '減点されるポイント',
            xx28: '赤い封筒を登録する',
            xx29: '注文番号',
            xx30: '時間',
            xx31: '取引後の残高',
            xx32: 'コミッション率',
            xx33: 'ジンベエ会員の皆様',
            xx34: 'ダウンラインメンバー',
            xx35: '今月登録した新会員',
            xx36: '今月のアクティブメンバー',
            xx37: '今月の純勝敗',
            xx38: 'リベート率',
            xx39: '今月のコミッション',
            xx40: 'QRコードを宣伝する',
            xx41: 'プライベート ドメイン名',
            xx42: 'オフラインメンバーのリスト',
            xx43: 'コミッションレポート',
            xx44: '会員アカウントを検索する',
            xx45: 'メンバーアカウント',
            xx46: '最終ログオン時刻',
            xx47: '/登録日',
            xx48: '当期のコミッション',
            xx49: '総勝敗',
            xx50: 'リベート総計',
            xx51: 'ボーナス',
            xx52: 'キャッシュフロー損失手数料',
            xx53: 'ゴールドフロー料金',
            xx54: 'VIPコミッション追加',
            xx55: 'VIP賞金',
            xx56: 'コミッション率',
            xx57: '財務概要表',
            xx58: '当期のコミッション',
            xx59: 'プラットフォーム料金',
            xx60: '出金口座',
            xx61: 'cam入金',
            xx62: 'cam引き出し',
            xx63: '支払い',
            xx64: '支払い完了',
            xx65: '支払いに失敗しました',
            xx66: '注文完了時間',
            xx67: 'チャージ方法',
            xx68: '通貨',
            xx69: '出金方法',
            platform: 'ゲームプラットフォーム',
            bitStatus: '状態',
            xx70: '有効なベット',
            xx71: 'ゲームが進行中です',
            xx72: '無効な賭け金',
            xx73: '開始時間',
            xx74: '仕上げる時間',
            xx75: '賭けの詳細',
            xx76: '注文番号',
            xx77: 'プレイヤーID',
            xx78: 'テーブル番号',
            xx79: 'リザルト',
            xx80: 'ゲームコード',
            xx81: '言語',
            xx82: 'JBbetカスタマーサービスへようこそ',
            xx83: 'HI,お客様各位',
            xx84: 'クメール語と英語によるカスタマーサービス',
            xx85: 'ベトナムのカスタマーサービス',
            xx86: '中国のカスタマーサービス',
            xx87: '英語でのカスタマーサービス',
            xx88: '銀行カード名',
            xx89: '銀行口座',
            xx91: '銀行名',
            xx92: '年中無休カスタマーサポート',
            xx93: 'キャッシュカードを追加する',
            xx94: '資金の安全性を確保するために、引き出しアドレス (TRC20) をバインドしてください。',
            xx95: 'ロビーに戻ってもよろしいですか?ゲームコインが正常に返却されない場合は、マイページのワンクリック返金機能をご利用ください。',
            xx96: 'リーグ名',
            xx97: 'ホームチーム',
            xx98: 'アウェイチーム',
            xx99: '分数',
            xx100: 'すべての情報は必須です',
            xx101: 'についてJbbet',
            xx102: 'JBbetIt is a favorite gambling and entertainment venue for many people. This is a highly regarded playground among todays top reputable and premium betting sites. Jbbet not only enjoys a high reputation in Southeast Asia, but also occupies an important position in the gambling market of the entire Asian market.',
            xx103: 'The birth of JBbet',
            xx104: 'Despite its recent popularity, JBbet has been operating in the Asian market for many years. JBbet was established in 2005 and officially operates in Cambodia. Thanks to PAGCOR for its cooperation in issuing the operating license. This bookmaker has many gambling games that are popular among players',
            xx105: 'JBbet’s products and services',
            xx106: 'Ln addition to sports games and live entertainment, we also cooperate with many major entertainment companies. Therefore, we always ensure that we provide the highest quality entertainment and gambling services and products. Not only does it bring a reputable and professional betting market. There is also a diverse playground, bringing the best options for everyone',
            xx107: 'Sports competition',
            xx108: 'The most popular betting products. The world of sports betting, including football, basketball, volleyball, tennis... is fascinating. Players can place bets and watch games from the biggest season in the world. Satisfy your betting passion with accurate odds and attractive rebate offers。',
            xx109: 'Live casino',
            xx110: 'Unlike traditional casino tables, today we also have live casinos. After registering an account at JBbet, players can join the live casino with hot girls. Youll join the table with real people via a live screen. Not only does it increase attraction and fun, it also creates more authenticity and professionalism for players',
            xx111: 'Slots',
            xx112: 'JBbetOne of the most attractive entertainment products is slot machine games. We offer hundreds of slot machines from major game manufacturers around the world. Provide diverse experiences through different colors, content and prizes',
            xx113: 'Poker',
            xx114: 'In addition to live entertainment, JBbet also offers gambling games with eye-catching 3D graphics. From traditional board and card games to dragon and tiger, baccarat, blackjack and other games,... ',
            xx115: 'Fishing game',
            xx116: 'Fishing is a very familiar game that is both highly entertaining and a great way to make extra money. Come to JBbet, fishing becomes more diverse and the content and graphics are more unique',
            xx117: 'Advantages of JBbet',
            xx118: 'Possess a legal license issued by the European Malta MGA. Registered in the British Virgin Islands, it is an internationally recognized legal company. Therefore, players participating in betting and casinos here do not have to worry 2about legality. JBbet is equipped with advanced technical equipment and adopts professional security system. Therefore, all player information will be safe and will not be exposed to the outside world. If there is an unauthorized third party, we will promptly detect and prevent it. In addition, we always have the most professional customer service staff. Ensure 24/7 support and answers to all your questions. This advantage is also what makes us most confident when introducing Jbbet to international friends. Therefore, please contact customer service for advice and answers when needed',
            xx119: 'We hope you can get to know us better. do not forget. Register a JBbet account to be able to participate in this exciting betting playground',
            xx120: 'この通貨はサポートされていません。通貨を交換または切り替えるにはウォレットのページにアクセスしてください。',
            xx121: '以下に示すように、銀行カード名を入力してください',
            xx122: 'USDTアドレス(TRC20)',
            xx123: 'アドレス管理',
            xx124: 'すべての記録',
            xx125: 'リチャージ記録',
            xx126: '出金記録',
            xx127: '取り消し',
            xx128: '申請中',
            xx129: '確認済み',
            xx130: '却下した。',
            xx131: 'スポーツゲーム',
            xx132: 'ゲーム記録',
            xx133: '結果',
            xx134: '決済済み',
            xx135: '未結',
            xx136: '取り消し',
            xx137: '昨日',
            xx138: '今日',
            xx139: '過去 7 日間',
            xx140: 'ほぼ30日',
            xx141: 'シングルベット',
            xx142: '訪問',
            xx143: 'チャンピオン',
            xx144: '自選日付',
            xx145: 'English customer service',
            xx146: '隠れる',
            xx147: 'テスト デポジット',
            xx148: 'ベット額',
            xx149: '体育',
            xx150: '体育',
            xx151: 'GamingSoft',
            xx152: '試合日',
            xx153: '転出',
            xx154: '転入',
            xx155: 'บริการลูกค้าไทย',
            xx156: 'Layanan pelanggan indonesia',
            xx157: 'JDB スロットマシン',
            xx158: 'PG スロットマシン',
            xx159: 'PP スロットマシン',
            xx160: 'NT スロットマシン',
            xx161: 'JDB 釣り',
            xx162: 'HI, いらっしゃいませ',
            xx163: 'アカウントをお持ちでない場合は、',
            xx164: '私の通貨',
            xx165: '交換',
            xx166: '赤い封筒を登録する',
            xx167: 'CMDシステム調整',
            xx168: '游戏买断',
            xx169: '游戏买入',
            xx170: 'プログレッシブジャックポット',
            xx171: 'ボーナス',
            xx172: 'ベットをキャンセルする',
            xx173: '回滚投注',
            xx174: 'ゲームオーバー',
            xx175: 'ゲーム賭博',
            xx176: '出金に失敗した場合の返金',
            xx177: '会員振替',
            xx178: '減点されるポイント',
            xx179: 'ボーナスポイント控除',
            xx180: 'ポイントを補う',
            xx181: 'ボーナス',
            xx182: 'CAM補充する',
            xx183: 'Huione 補充する',
            xx184: 'Udun補充する',
            xx185: 'オンラインリチャージ',
            xx186: 'オンライン引き出し',
            xx187: 'Huione 撤回する',
            xx188: 'withdraw',
            xx223: '彩金扣分',
            xx226: '体育结算'
        },
    },
    foot: {
        t1: "mago Berkomitmen pada perjudian yang bertanggung jawab, untuk informasi lebih lanjut silakan kunjungi",
        "t2": 'Mago.game は、Jinbei Group によって所有および運営されています。登録会社: Altacore N.V. 登録番号: 151002、登録住所: Dr. H. Fergusonweg 1, Gaito, Curacao。 support@Mago.gameまでご連絡ください。 2024 年 7 月 1 日現在、ライセンス番号 OGL/2023/109/0075 でキュラソー島ゲーミング管理委員会からライセンスを取得しています。支払い代理店はアジア ペイメント サービス シンガポールです。',
        t3: "mendukung",
        t4: "bekerja samaMitra",
        t5: "media berita",
    },
    new: {
        "ting": "ホール入口",
        "magoRecommendation": "マゴ推奨",
        "slots": "スロットマシン",
        "liveCasino": "ライブカジノ",
        "gameShow": "ミニゲーム",
        "tableGames": "テーブルゲーム",
        "fishing": "釣りゲーム",
        "bingo": "ビンゴ",
        "newGame": "新しいゲーム",
        "liveCasinoTxt": "ライブカジノ",
        "searchGamePlaceholder": "ゲームを検索する",
        "recharge": "リチャージ",
        "withdraw": "現金を引き出す",
        "favorites": "お気に入り",
        "recentGames": "最近のゲーム履歴",
        "vipClub": "VIPクラブ",
        "alliancePlan": "アライアンス計画",
        "gamesTitle": "ゲーム",
        "sports": "スポーツ活動",
        "gameProvider": "ゲームプロバイダー",   //
        "languageSelection": "言語の選択",
        "timezone": "タイムゾーン",//
        "onlineSupport": "オンラインサポート",
        "aboutUs": "私たちについて",
        "antiMoneyLaundering": "反洗钱政策",
        "license": "许可证",
        "responsibleGambling": "責任あるギャンブル",
        "termsOfService": "利用規約",
        "logout": "ログアウト",
        "fbLiveCasino": "FBライブ動画",
        "loadingText": "読み込み中",
        "recommendation": "マゴ推奨",
        "myFavorites": "私のコレクション",
        "gameHistory": "ゲーム記録",
        "noData": "まだデータがありません",
        "noFavoritesData": "まだ収集データがありません",
        "noGamesData": "まだゲームデータがありません",
        "gameProviderPlaceholder": "ゲームプロバイダー",
        "exclusiveAlliancePlan": "独占的なアフィリエイト プログラム",
        "joinMogo": "mogo のアフィリエイト プログラムに参加して、ブランドを推奨して収入を稼ぎましょう!当社のパートナーになり、利益の最大 55% を獲得してください。推薦されたプレイヤーの収益から一定の割合のコミッションを受け取ります。一緒に成功を分かち合いましょう",
        "becomePartner": "パートナーになる",
        "exclusiveVIPExperience": "比類のない VIP 体験",
        "unlockExclusiveBenefits": "限定特典のロックを解除して、条件なしですぐに出金できるボーナスを獲得しましょう。",
        "register": "登録する",
        "vipRankingSystem": "マゴVIPランキングシステム",
        "bettingAmount": "ベット額",
        "vipClubBenefits": "マゴVIPクラブの特典",
        "yourVIPProgress": "VIP の進捗状況",
        "currentLevel": "現在のレベル",
        "currentLevelValue": "V",
        "expectedWeeklyMonthlyBonus": "プレイ量に応じて毎週および毎月のボーナスが期待できます。プレイすればするほど、より多くのボーナスを獲得できます。",
        "exclusiveVIPServiceRepresentative": "あなたのベッティングのニーズに応え、サポートする専任の VIP サービス担当者が割り当てられます。。",
        "recentGamePerformanceBonus": "不運？レベルアップするたびに、賭け金は損失に基づいて追加の資金を提供します。",
        "levelUpBonus": "次の各レベルに到達するとボーナスを獲得できます。到達したレベルが高いほど、アップグレードボーナスも大きくなります。。",
        "bespoke": "専任の VIP サービス担当者と協力して、ベッティングのニーズに合わせて特典を調整します。",
        "bronze": "銅",
        "silver": "銀",
        "gold": " 金",
        "platinum": "プラチナI～III",
        "inpWithPwd": "ファンドのパスワードを入力してください",
        "inpConfirmPwd": "資金確認パスワードを入力してください",
        "pwdMismatch": "2 つのパスワードが矛盾しています",
        "nameRequired": "名前を空にすることはできません",
        "pwdLabel": "基金のパスワード",
        "confirmPwdLabel": "ファンドのパスワードを確認する",
        "fillPrompt": "プロンプトに従って入力してください",
        "saveContinue": "保存して続行",
        "confirmDetails": "詳細を確認してください",
        "fillDetails": "追加サービスのロックを解除するには、詳細を入力し、本人確認を行ってください。提供される情報は非公開で安全です。",
        "realNameLabel": "本名",
        "birthdayLabel": "あなたの誕生日",
        "promotionTitle": "活動",
        "eventTitle": "テニスの試合",
        "bonusTitle": "エースボールダブルボーナス",
        "readMore": "続きを読む",
        "experienceNow": "今すぐ試してください",
        "viewAll": "すべて見る",
        "playing": "遊んでいる人々",
        "cancel": "キャンセル",
        "start": "始める",
        "login": "ログイン",
        "collect": "集める",
        "today": "今日",
        "win": "勝つ",
        "draw": "フラット",
        "lose": "失う",
        "setWallet": "ウォレットを設定してプレイを始めましょう！",
        "goSetPwd": "設定に移動",
        "pointsSystem": "ポイント制度",
        "Browse": "ブラウズ",
        "Casino": "カジノ",
        "Bets": "ベットスリップ",
        "Sports": "スポーツ",
        "Chat": "チャット",//
        "firstTelegram": "TELEGRAM miniAPP",
        "miniProgramCasino": "オンラインカジノ",
        "followTelegram": "Telegram の公式チャンネルをフォローしてください",
        "trialBonusChannel": "トライアルボーナスを受け取る",
        "enterNow": "지금 입장하세요",
        "announcement": "발표",
        "magoJoinFB": "FBライブカジノ",
        "exclusiveAnchor": "新しいデザインスタイル",
        "t4": "違う体験をお届けします",
        "playNow": "지금 와서 놀아보세요",
        "aboutUs1": 'Mago娱乐城属于金贝集团所属经营，Mago是亚洲值得信赖的在线赌博网站，自从正式投入服务至今最完整和最新的在线赌博游戏。 我们有一个简单的使命和愿景是提供诚实和信任的服务。',
        "aboutUs2": "除了体育游戏和真人娱乐,我们还与许多主要娱乐公司合作。因此,始终确保提供最优质的娱乐博彩服务和产品。不仅带来了信誉良好且专业的博彩市场。还有一个多样化的游乐场,为每个人带来最好的选择。",
        "aboutUs3": '最热门的投注产品。体育博彩领域，包括足球、篮球、排球、网球比赛,...非常迷人。玩家参与投注，观看世界上最大的赛季的激烈比赛。通过准确的赔率，有吸引力的回扣优惠满足您对投注的热情。',
        "aboutUs4": '与传统的赌场赌桌不同,今天我们也有真人娱乐场。在Mago 注册帐户后，玩家可以与辣妹一起加入真人娱乐场。您将通过直播屏幕与真人一起加入赌桌。不仅增加了吸引力和趣味性，还为玩家创造了更多的真实性和专业性',
        "aboutUs5": 'Mago最吸引人的娱乐产品之一是老虎机游戏。我们提供来自全球主要游戏制造商的数千种老虎机。提供具有不同颜色,内容和奖品的多样化体验。',
        "aboutUs6": '除了真人娱乐,Mago仍然提供具有醒目 3D 图形的赌博游戏。来自传统的棋牌游戏，游戏如龙虎、百家乐、二十一点,...',
        "aboutUs7": '捕鱼是一个非常熟悉的游戏,既具有很高的娱乐性,又有助于赚取额外的钱。来到Mago,捕鱼变成了具有更多独特内容和图形的多样性。',
        "aboutUs8": '拥有库拉索GCB颁发的合法执照。注册于威廉斯塔德,是受国际认可的合法公司因此,在这里参与投注和赌场的玩家不必担心合法性。Mago配备了先进的技术设备,采用专业的安全系统。因此,玩家的所有信息都将是安全的，不会暴露在外部。如果有未经授权的第三方,我们也会及时发现并防止。另外我们始终拥有最专业的客户服务人员。确保 24/7 全天候支持并回答您的所有问题。这种优势也是我们在向国际友人介绍Mago时最有信心的。因此,在需要时,请联系客服人员以获取建议和答案。我们希望您能更好地了解我们。不要忘记。注册一个Mago 帐户,以便能够参与这个令人兴奋的投注游乐场',
        "aboutUs2T": "游戏产品",
        "aboutUs3T": "体育游戏",
        "aboutUs4T": "真人娱乐 ",
        "aboutUs5T": "老虎机游戏 ",
        "aboutUs6T": "棋牌游戏 ",
        "aboutUs7T": "捕鱼游戏 ",
        "aboutUs8T": "Mago娱乐城的优势 ",
        "my": '私の',
        "active": '私の',
    }
}
